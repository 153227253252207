import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import "./Login.css";
import { useAuthClient } from '../../../hooks/useAuthClient/useAuthClient';
import { useNavigate, useParams } from "react-router-dom";
import ShowSnackbars from '../../../components/menssages/ShowSnackBar';

const LoginPage = () => {
    const { signIn } = useAuthClient();
    const navigate = useNavigate();
    const { client } = useParams();
    const [user, setUser] = useState("");
    const [msg, setMsg] = useState({
      open: false,
      text: '',
      type: 'success'
    });

    useEffect(() => {
      handleLogin(client, true)}, []);

    const showMsg = (type, msg) => {
      setMsg({
        open: true,
        text: msg,
        type: type
      })
    }   
  
    const handleLogin = async (key, auto) => {  

      if (!key) {

        if(!auto){showMsg("error","Preencha todos os campos")};
        
        return;
      }
  
       const res = await signIn(key);

      if (!res.Authenticated) {
        if(!auto){showMsg("error", res.msg)};
        return;
      }
      
      navigate("/home"); 
      window.location.reload(false);
    };

    function extractNumbers(inputString) {

      const numbers = inputString.match(/\d+/g);

      if (numbers) {
        return numbers.join('');
      } else {
        return '';
      }
    }

    const handleChange = (e) => {
      const numbers = extractNumbers(e.target.value)
      setUser(numbers);
    };
  

  return ( 
    <div className='div-login-fundo' height="100%"  style={{backgroundImage: `url(/img/FUNDO.png)`}}>
      <ShowSnackbars msg={msg} setMsg={setMsg}/>
      <div className='box-login'>
        <Typography variant="h5" gutterBottom>
            Sirrus Atendimento
        </Typography>
        <Typography  gutterBottom>
          Bem vindo ao atendimento Sirrus, realize login utilizando seu CNPJ ou CPF.
        </Typography>
        <Divider sx={{m: 1}}/>
        <TextField sx={{ m: 1, width: '30ch' }}
          value= {user}
          id="campo-user-cnpj"
          label="CNPJ/CPF"
          name="user"
          onChange={(e) => handleChange(e)}
        />     
        <Button variant="outlined" sx={{ m: 1, width: '32ch', height: '7ch' }} onClick={() => handleLogin(user, false)}>Entrar</Button>  

        <div className='div-by' >
          <p>&copy; Sirrus Sistemas {new Date().getFullYear()}</p>
          <p> Desenvolvido por Ricardo Costa</p>
        </div> 
      </div> 
    </div>
  )
}

export default LoginPage