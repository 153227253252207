import {  Grid } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import CardManualYoutube from '../cardManual/CardManual';
import VisualizarManual from '../VisualizarManual/VisualizarManual';
import AppBarPrincipal from '../../appBar/Appbar';
import SearchBar from '../../searchBar/SearchBar';

let urlYoutube = `https://www.googleapis.com/youtube/v3/search`

const Manuals = (props) => {

  const [params, setParams] = useState({
    part: "snippet",
    key: process.env.REACT_APP_KEY_YOUTUBE,
    channelId: process.env.REACT_APP_ID_SIRRUS,
    maxResults: 10,
    type: "video",
    order: "date", 
    list: '',
    q: ''
  } 

  )
    const [changed, setChanged] = useState(false);
    const { data } = useFetchData(urlYoutube, params, changed)
    const [filter, setFilter] = useState('');
    const [video, setVideo] = useState('');
    const [manual, setManual] = useState({
      id: '',
      titulo: '', 
      descricao: '', 
      link: '', 
      ramo: 4, 
      sistema: '', 
      categoria: '', 
      subcategoria: '', 
      palavras_chaves: ''
    });

  
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('1');

    // Filtro
    const [playlist, setPlaylist] = useState('all');
    const [anchorEl, setAnchorEl] = useState(null);
    const openFilter = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
      setAnchorEl(null);
    }

    const handleChangeFilter = (e) => {
      setPlaylist(e.target.value);
    }
    //Fim do Filtro 

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }


    const handleBuscar = () => {
      setParams((prevValues) => (
        {...prevValues, 'q': filter}
      ))
      setChanged(!changed)
    }      

  return (
    <Box textAlign={"center"} sx={{overflow: 'scroll', width: '100%', height: '100%' }}>
        {(!props.admin) ? <AppBarPrincipal/> : ''}
        <SearchBar title='Em que podemos ajudar?' filter={filter} setFilter={setFilter} handleSearch={handleBuscar}/>
        <Box sx={{ width: '100%', typography: 'body1', marginTop: 4, paddingLeft: 1, }}>
          <VisualizarManual open={open} setOpen={setOpen} video={video} setVideo={setVideo}/>
          <Grid container spacing={1} columns={ { xs: 1, sm: 2, md: 3, lg: 4, xl: 5, } }  >                
                    {data.items && data.items.map((item) =>(
                      <Grid item xs={1} sm={1} md={1}  xl={1} sx={{display: 'flex', justifyContent: 'center',width: '100%', }}>
                        <CardManualYoutube values={item} setManual={setManual} setOpen={setOpen} manual={manual} setVideo={setVideo}/>   
                      </Grid>             
                    ))     
                    }              
          </Grid> 
        </Box>  
    </Box>
  )
}

export default Manuals