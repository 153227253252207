import { Button,  Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../hooks/useMessages/useMessages';


let urlCategories = process.env.REACT_APP_API+ "/files/categories";

const FilesCategory = (props) => { 
  const { editData, postData, deleteData  } = useFetchData();
  const { ShowMessage, msgConfirm } = useShowMessages();
  const handleChangeValues = (value) => {

    props.setValues((prevValues) => ({
      ...prevValues, 
      [value.target.name] : value.target.value,
    }));

  };
  
  const handleClose = () => {
    props.setOpen(false)
  }

  const handleClickSend = async () => {
    const res =  (!props.editing) ? await postData(urlCategories, props.values) : await editData(urlCategories, props.values);
    if(res.success){
      ShowMessage('success', res.msg);
      props.setChanged(!props.changed);
      handleClose();
    }else{
      ShowMessage('error', res.msg);
    }
  }


  const handleDeleteCategory = async() => {
    msgConfirm('Exclusão de Categoria', 'Confirma a exclusão da categoria selecionada?',
      'Sim, pode excluir', 'Cancelar', async () => {
        const res =  await deleteData(urlCategories, {id: props.values.id}); 
        if(res.success){
          ShowMessage('success', res.msg);
          props.setChanged(!props.changed);
          handleClose();
        }else{
            ShowMessage('error', res.msg);
        }
     })
  }

  return (  
      <Dialog open={props.open} >
        <DialogTitle >Cadastro de Categorias</DialogTitle>
        <DialogContent> 
          <Grid container spacing={1} columns={ 12 } sx={{float: "center",}} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
              <TextField
                className='label-MovtAtendimento-admin'
                autoFocus
                name="name"
                value={props.values.name}
                defaultValue=" "
                label="Descrição"
                type="text"
                onChange={handleChangeValues}
                fullWidth
                variant="filled"
              />                              
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingRight: 3}}>
            <Button
              onClick={handleDeleteCategory} 
              style={{display: (props.editing) ? '' : 'none', }} 
              variant="outlined" color="error"
              >
                Excluir
            </Button>
          <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
          <Button onClick={() => handleClickSend()} variant="outlined" color="success">Salvar</Button>
        </DialogActions>
      </Dialog>    
  )
}

export default FilesCategory