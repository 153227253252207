import { Box, Modal } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react'
import './VisualizarManual.css'
const VisualizarManual = (props) => {

    const handleClose = () => {
        props.setOpen(false);
        props.setVideo('');
    };

  return (
    <Modal className='dialog-video' open={props.open}
    
    keepMounted
        fullWidth    
        onClose={handleClose}  
        sx={{display: `asdasdasdas`}}
    >
      <Box className='box-video' 
        sx={{
          width:{xs: "90%", sm: "90%", md: "80%", lg: "70%", xl: "50%"},
          height:{xs: "30%", sm: "60%", md: "60%", lg: "60%", xl: "56%"}
        }}
      >
        {
          (props.video != '') ? 
          <> 
            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${props.video}`} 
              title="" 
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="0"
            >
            </iframe>
          </>
         : <CircularProgress/>
        }
        

      </Box>

    </Modal>
  )
}

export default VisualizarManual