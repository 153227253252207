import { Box, Divider, SpeedDial, SpeedDialIcon, Typography } from '@mui/material'
import React, { useState } from 'react';
import {TableCategories} from '../TableCategories/TableCategories';
import Category from '../category/Category';


const PageCategories = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ id: 0, name: '' });
  const [editing, setEditing] = useState(false);
  const [changed, setChanged] = useState(false);

  const handleNewCategory = () => {
    setValues({ id: 0, name: '' });
    setOpen(true);
    setEditing(false);
  }

  const handleChanged = () => {
    setChanged(!changed);
  }


  return (
    <div style={{padding: 20, height: '92vh'}}>
      <Box className="header-atendimento" sx={{mb: 1}}>
          <Typography variant="h4"  color="text.secondary" >
              Cadastro de Categorias e Sub Categorias
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Realize o cadastro das categorias que serão utilizadas nas publicações.   
          </Typography>
      </Box>
      <Divider sx={{mb: 2}}/>
      <Box sx={{m: 2}}>
       <Category open={open} setOpen={setOpen} values={values} setValues={setValues} editing={editing} setChanged={setChanged} changed={changed}/>
       <TableCategories open={open} setOpen={setOpen} values={values} setValues={setValues} setEditing={setEditing} handleChanged={handleChanged} changed={changed}/>
      </Box>
      <SpeedDial
            ariaLabel="Nova Categoria"
            sx={{ position: 'fixed', bottom: 60, right: 80, }}
            icon={<SpeedDialIcon  />}
            onClick={() => handleNewCategory()}
            >
      </SpeedDial>   
    </div>
  )
}

export default PageCategories