import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Autocomplete, Badge, Box, Button, Dialog, DialogActions, DialogContent,  DialogTitle, Grid, IconButton, InputBase, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox';

//hooks 
import {useFetchData} from '../../../hooks/useFetchData/useFetchData';

let url = process.env.REACT_APP_API+ "/requests/all";
let urlUsers = process.env.REACT_APP_API+ "/user/all";

export default function RequestsTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({
    FilterDate: false,
    InitialDate: dayjs(), 
    FinalDate: dayjs(), 
    user: 0,
  });
  const [elevationDate, setElevationDate] = useState(0);

  const { data : requestsList} = useFetchData(url, 
    {
      filter: filter, 
      page: page, 
      rowsPerPage: rowsPerPage, 
      FilterDate: filters?.FilterDate,
      InitialDate: filters?.InitialDate,
      FinalDate: filters?.FinalDate,
      user: filters?.user
    }, props.changed);

  const { data : listUsers } = useFetchData(urlUsers);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.handleChanged();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    props.handleChanged();
  };

  const handleOpen = (values) =>{
    props.setValues(values);
    props.setEditing(true);
    props.setOpen(true);
  }
 
  const handleSetFilter = (filter) => {
    setFilter(filter.target.value);
  }

  const handleSearch = () => {
    props.handleChanged();
  }


  const countFilter = ()=>{

    let count = 0;

    if(filters.FilterDate){
      count = count + 1;
    }
    if(filters.user){
      count = count + 1;
    }    
    setFilters((prevValues) => (
      {...prevValues, 
        'count' : count}
    )); 
  }


  const handleChangeUser = (values) => {
    setFilters((prevValues) => (
      {
          ...prevValues, "user": (!values) ? null : values.id,
    }));  
    setFilters((prevValues) => (
      {
          ...prevValues, "nameUser": (!values) ? '' : values.name,
    }));    
  }


  const handleChangeFiltersDate = (field, value) => {
   
    setFilters((prevValues) => (
      {...prevValues, 
        [field] : dayjs(value),}
    ));

  };

  const handleChangeDateChecked = (checked) => {
    setFilters((prevValues) => (
      {...prevValues, 
        'FilterDate' : checked,}
    ));
    (checked) ? setElevationDate(5) : setElevationDate(0)

    if(checked){
      setFilters((prevValues) => (
        {...prevValues, 
          'count' : 1}
      ));
    }
  }

  const getStatus = (status) => {
    switch(status){
      case 0: return 'Check-In em Aberto';
      case 1: return 'Nova Solicitação';
      case 2: return 'Aguardando';
      case 3: return 'Em Atendimento';
      case 4: return 'Concluído';
      case 5: return 'Finalizado';
      case 6: return 'Cancelado';
    } 
  }

  const handleCloseFilter = () => {
    setOpenFilter(false);
  }

  const handleOpenFilter = () => {
    setOpenFilter(true);
  }

  const applyFilter = () => {
    props.handleChanged();
    handleCloseFilter();
    countFilter();
  }



  return (
    <Box sx={{ width: '100%', height: '100%'}}>
      <Paper elevation={3} sx={{ p: '2px 4px', display: 'flex',  mb: 2, border: '1px solid rgba(0, 0, 0, 0)', ':hover': { border: '1px solid' } }}>
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Pesquisar"
            value={filter}
            onChange={handleSetFilter}
            onKeyDown={(e) => {if(e.key === "Enter")handleSearch()}}
        />
        <IconButton type="button" sx={{ p: '10px' }} title='Filtros' onClick={handleOpenFilter}>
        <Badge color="primary" variant="standard" badgeContent={filters?.count}>
          <FilterListIcon />
        </Badge>        
        </IconButton>          
        <IconButton type="button" sx={{ p: '10px' }} title='Buscar' onClick={handleSearch}>
            <SearchIcon />
        </IconButton>
      </Paper >
      <Paper >
        <TableContainer sx={{ overflow: 'scroll', height: '65vh'}} >
          <Table stickyHeader  sx={{ width: '100%' }} >
            <TableHead>
              <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Serviço</TableCell>
                  <TableCell>Código Gerente</TableCell>    
                  <TableCell>Status</TableCell>     
                  <TableCell>Visualizar</TableCell>                                              
              </TableRow>
            </TableHead>
            <TableBody>
            {requestsList && requestsList.map((row) => (
              <TableRow key={row.id} sx={{height: '25px'}}>
                <TableCell align="left">{row.id}.{row.sequence}</TableCell>
                <TableCell component="th" scope="row">
                  {row.nome_fantasia}
                </TableCell>
                <TableCell align="left">{row.created_date}</TableCell>
                <TableCell align="left">{row?.type_service_description}</TableCell>
                <TableCell align="left">{row.id_gerente}</TableCell>
                <TableCell align="left">{getStatus(row.status)}</TableCell>
                <TableCell align="left">
                  <Button onClick={() => handleOpen(row)}>
                      <VisibilityIcon />
                  </Button></TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage='Registros por página'
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={(requestsList && requestsList) ? Math.ceil(requestsList[0]?.count) : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog open={openFilter} onClose={handleCloseFilter}> 
        <DialogTitle>Filtrar Atendimentos</DialogTitle>
        <DialogContent>
        <Grid container spacing={1} columns={ 12 } sx={{float: "center"}} >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
            <Autocomplete
              disablePortal
              fullWidth
              options={listUsers}
              sx={{mt: 1}}
              getOptionLabel={ (option) => option['name'] || option }    
              value={filters?.nameUser}
              onChange={(event, values) => handleChangeUser(values)}
              renderInput={(params) => 
              <TextField
                name="user"
                {...params} label="Atendente" 
              />}
              />          
            </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
            <Paper elevation={elevationDate} sx={{p: 1}}>
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker 
                    label="Data Inicial" 
                    value={dayjs(filters.InitialDate)}
                    onChange={(newValue) => handleChangeFiltersDate('InitialDate', newValue)}
                    format="DD/MM/YYYY" 
                  />
                  <DatePicker 
                    label="Data Final" 
                    value={dayjs(filters.FinalDate)}
                    onChange={(newValue) => handleChangeFiltersDate('FinalDate', newValue)}
                    format="DD/MM/YYYY" 
                  />
                <Checkbox
                  checked={filters?.FilterDate}
                  onChange={(e) => handleChangeDateChecked(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />                  
                </DemoContainer>         
              </LocalizationProvider>
            </Paper> 
          </Grid>

        </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={applyFilter}>Aplicar</Button>
        </DialogActions>        
      </Dialog>
    </Box>
  );
}