import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SubCategories from '../subCategories/SubCategories';

let urlCategories = process.env.REACT_APP_API+ "/helpdesk/categories";
let urlSubCategories = process.env.REACT_APP_API+ "/helpdesk/subcategories";

const Category = (props) => {
  const [values, setValues] = useState({});
  const [ open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  
  const { editData, postData, deleteData  } = useFetchData(urlCategories);

  const subCategories = useMemo(() => {
    return props.values.SubCategories;
  }, [props.values.SubCategories]);


    const { ShowMessage } = useShowMessages();
    const handleChangeValues = (value) => {
  
      props.setValues((prevValues) => ({
        ...prevValues, 
        [value.target.name] : value.target.value,
      }));
  
    };
  
    const handleClose = () => {
      props.setOpen(false)
    }
  
    const handleClickSend = async () => {
      const res =  (!props.editing) ? await postData(urlCategories, props.values) : await editData(urlCategories, props.values);
      if(res.success){
        ShowMessage('success', res.msg);
        props.setChanged(!props.changed);
        handleClose();
      }else{
        ShowMessage('error', res.msg);
      }
    }

    const handleNewSubCategory = (e) => {
      if(props.values.id === 0){
        return ShowMessage('error', 'Salve seu registro antes de inserir as subcategorias');   
      }
      setValues({
        category: props.values.id,
        name: '',
      });
      setEditing(false);
      setOpen(true);
    }

    const handleEditSubCategory = (e, index) => {
      setValues({
        id: e.id,
        name: e.name,
        index: index
      }); 
      setEditing(true);
      setOpen(true);
    }

    const handleDeleteCategory = async() => {

      if(props.values.SubCategories.length > 0){
        return ShowMessage('error', 'Não é possível apagar categorias que possuam subcategorias'); 
      }
      const res =  await deleteData(urlCategories, {id: props.values.id}); 
      if(res.success){
        ShowMessage('success', res.msg);
        props.setChanged(!props.changed);
        handleClose();
      }else{
          ShowMessage('error', res.msg);
    }
    }

    const handleDeleteSubCategory = async(id, index) => {
      const values = [props.values.SubCategories]

      const res =  await deleteData(urlSubCategories, {id: id}); 
      if(res.success){
          values[0].splice(index, 1);
          props.setValues((prevValues) => ({
            ...prevValues, 
            'SubCategories' : values[0],
          }));  
      }else{
          ShowMessage('error', res.msg);
    }

}

  return (  
      <Dialog open={props.open} >
        <DialogTitle >Cadastro de Categorias</DialogTitle>
        <DialogContent>
        <SubCategories open={open} setOpen={setOpen} values={values} setValues={setValues} editing={editing} editData={props.setValues} data={props.values}/>  
          <Grid container spacing={1} columns={ 12 } sx={{float: "center",}} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
              <TextField
                className='label-MovtAtendimento-admin'
                autoFocus
                name="name"
                value={props.values.name}
                defaultValue=" "
                label="Descrição"
                type="text"
                onChange={handleChangeValues}
                fullWidth
                variant="filled"
              />                              
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11} marginBottom={1} marginTop={1}>
            <Typography>Subcategorias</Typography>  
            </Grid>
            
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} marginBottom={1} marginTop={1}>
            <Tooltip title='Novo' color='white'>
                  <IconButton onClick={handleNewSubCategory} >
                    <AddCircleOutlineIcon color='primary'/> 
                  </IconButton>
            </Tooltip>                                       
            </Grid>       
                  
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
                
                <Box sx={{float: "left",  '& .MuiChip-root': { m: 1}, marginBottom: 2}}>
                  {subCategories && subCategories.map((cat, index) => (
                    <Chip 
                    label={ "#"+cat.id +" "+ cat.name} 
                    variant="outlined" 
                    onClick={() => handleEditSubCategory(cat, index)}
                    onDelete={() => handleDeleteSubCategory(cat.id, index)}
                    deleteIcon={<DeleteIcon />}
                    
                  />  
                  ))}
                </Box>                         
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingRight: 3}}>
            <Button
              onClick={handleDeleteCategory} 
              style={{display: (props.editing) ? '' : 'none', }} 
              variant="outlined" color="error"
              >
                Excluir
            </Button>
          <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
          <Button onClick={() => handleClickSend()} variant="outlined" color="success">Salvar</Button>
        </DialogActions>
      </Dialog>    
  )
}

export default Category