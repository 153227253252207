import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {  Box, Button, Dialog, DialogActions, DialogContent,  DialogTitle } from '@mui/material';

import dayjs from 'dayjs';


//hooks 
import {useFetchData} from '../../../hooks/useFetchData/useFetchData';
import Loading from '../../loading/loading';

let url = process.env.REACT_APP_API+ "/requests/id";

const RequestsTableModalCharts = (props) => {
 


  const { getDataDirectly, loading} = useFetchData();

  const handleOpen = (values) =>{
    props.setValues(values);
    props.setOpenRequest(true);
  }

  const getRequest = async(values) => {
    await getDataDirectly(url, {
        id: values.service_id
    },
    async (response) => {
        handleOpen(response); 
    }
    )
  }


 
  const getStatus = (status) => {
    switch(status){
      case 0: return 'Check-In em Aberto';
      case 1: return 'Nova Solicitação';
      case 2: return 'Aguardando';
      case 3: return 'Em Atendimento';
      case 4: return 'Concluído';
      case 5: return 'Finalizado';
      case 6: return 'Cancelado';
    } 
  }

  const handleClose = () => {
    props.handleChanged();
    props.setOpen(false);
  };

  return (
    <Dialog open={props.open} maxWidth="md">
      <DialogTitle>
         Atendimentos - {props?.values[0]?.nameUser}
      </DialogTitle>
      <DialogContent >  
        <Loading open={loading} />              
        <Box sx={{ width: '100%', height: '100%'}}>
        <Paper >
          <TableContainer sx={{ overflow: 'scroll', height: '65vh'}} >
            <Table stickyHeader  sx={{ width: '100%' }} >
              <TableHead>
                <TableRow>
                    <TableCell>id</TableCell>
                    <TableCell>OS</TableCell>  
                    <TableCell>Cliente</TableCell>
                    <TableCell>Inicio</TableCell>  
                    <TableCell>Conclusão</TableCell>                     
                    {/* <TableCell>Status</TableCell>      */}
                    <TableCell>Visualizar</TableCell>                                              
                </TableRow>
              </TableHead>
              <TableBody>
              {props.values.length > 0 && props?.values.map((row) => (
                <TableRow key={row.service_id} sx={{height: '25px'}}>
                  <TableCell align="left">{row.id}.{row.sequence}</TableCell>
                  <TableCell align="left">{row.id_gerente}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.nome_fantasia}
                  </TableCell>
                  <TableCell align="left">{row.start_day} : {row.start_hour}</TableCell>    
                  <TableCell align="left">{row.conclusion_day} : {row.conclusion_hour}</TableCell>                 
                  {/* <TableCell align="left">{getStatus(row.status)}</TableCell> */}
                  <TableCell align="left">
                    <Button onClick={() => getRequest(row)}>
                        <VisibilityIcon />
                    </Button></TableCell>
                </TableRow>
              ))}
              </TableBody>
            </Table>
          </TableContainer>
          
        </Paper>

      </Box>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} variant="outlined" color='error'>Fechar</Button>
      </DialogActions>
  </Dialog>
  );
};


export default RequestsTableModalCharts;