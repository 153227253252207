import React, { useState } from 'react';
import { Autocomplete,  Badge,  Box,  Button,  Checkbox,  Dialog,  DialogContent,  DialogTitle,  FormControl,  FormControlLabel,  Grid,  IconButton,  Input,  InputAdornment,  InputBase,  InputLabel,  MenuItem,  OutlinedInput,  Paper,  Select,  TextField,  } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useFetchData } from '../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../hooks/useMessages/useMessages';

export const FilesHeader = (props) => {
    
    const { ShowMessage } = useShowMessages();  

    const handleSetFilter = (value) => {
      props.setValues((prevValues) => ({
        ...prevValues,
        [value.target.name] : value.target.value,
      }));

      console.log(props.values)
      
      // sessionStorage.setItem((value.target.name === 'title') ? 'title' : 'file', (!values) ? null : value.target.value)
    }

    const handleSearch = () => {
      props.handleChanged();
    }

    const handleChangeCategory = (values) => {
      props.setValues((prevValues) => (
        {
            ...prevValues, 'category': (!values) ? null : values.id,
      }));  
      props.setValues((prevValues) => (
        {
            ...prevValues, 'categoryName': (!values) ? null : values.name,
      })); 
     sessionStorage.setItem('files_category', (!values) ? null : values.id) 
    }

  return (
    <Grid container spacing={1} columns={ 12 } sx={{float: "center", justifyContent: 'space-between', }} >
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{display: 'flex'}}>
      <Autocomplete
        disablePortal
        fullWidth
        options={props?.categories}
        getOptionLabel={ (option) => option['name'] || option }    
        value={props.values?.categoryName}
        onChange={(event, values) => handleChangeCategory(values)}
        renderInput={(params) => 
        <TextField
          name="categoryName"
          {...params} label="Categoria" 
        />}
      />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{display: 'flex'}}>
        <FormControl sx={{mr: 2}} fullWidth variant="outlined">
          <InputLabel htmlFor="title">Título</InputLabel>
          <OutlinedInput
            id="title"
            name="title"
            placeholder="Título do Arquivo"
            label="Título"
            value={props.values.filter}
            onChange={handleSetFilter}
            onKeyDown={(e) => {if(e.key === "Enter")handleSearch()}}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{display: 'flex'}}>
        <FormControl sx={{mr: 2}} fullWidth variant="outlined">
          <InputLabel htmlFor="file">Arquivo</InputLabel>
          <OutlinedInput
            id="file"
            name="file"
            placeholder="Nome do Arquivo"
            label="Arquivo"
            value={props.values.filter}
            onChange={handleSetFilter}
            onKeyDown={(e) => {if(e.key === "Enter")handleSearch()}}
          />
        </FormControl>
        <Button 
          variant='contained' 
          onClick={handleSearch}
          sx={{ height:'55px'}}
        >
          <SearchIcon/>
        </Button>  
      </Grid>
    </Grid>
  );
    
}