import { Box, Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemIcon, ListItemText, Paper, TextField } from '@mui/material';
import React, { useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';

let url = process.env.REACT_APP_API+ "/user/groups";

const UserGroup = (props) => {
    const { editData, postData } = useFetchData(url)
    const { ShowMessage } = useShowMessages();    
    const [expandedRequests, setExpandedRequests] = useState(false);
    const [expandedHelpdesk, setExpandedHelpdesk] = useState(false);
    const [expandedFiles, setExpandedFiles] = useState(false);
    const [expandedAdm, setExpandedAdm] = useState(false);
    const [expandedActualizations, setExpandedActualizations] = useState(false);

    const handleChangeValues = (value) => {
        props.setValues((prevValues) => ({
          ...prevValues, 
          [value.target.name] : value.target.value,
        }));
    
    }; 
    
    const handleClose = () => {
        props.setOpen(false)
    };

    const handleClickSend = async () => {
        const res =  (!props.editing) ? await postData(url, props.values) : await editData(url, props.values);
        if(res.success){
          ShowMessage('success', res.msg);
          handleClose();
        }else{
          ShowMessage('error', res.msg);
        } 
    };   

    const handleExpandRequests = () => {
        setExpandedRequests(!expandedRequests);
    };
    const handleExpandHelpdesk = () => {
        setExpandedHelpdesk(!expandedHelpdesk);
    };
    const handleExpandFiles = () => {
        setExpandedFiles(!expandedFiles);
    };
    const handleExpandActualizations = () => {
        setExpandedActualizations(!expandedActualizations);
    };
    const handleExpandAdm = () => {
        setExpandedAdm (!expandedAdm);
    };  
    
    const handleChangeCheckedItemMenu = (value, menu) => {
        props.setValues((prevValues) => ({
            ...prevValues,
            [menu]: (value) ? 'Y' : 'N'
        }))
    };    

    const handleChangeChecked = async (value, menu) => {

        props.setValues((prevValues) => ({
            ...prevValues,
            [value.target.name]: (value.target.checked) ? 'Y' : 'N'
        }));

        let checked = value.target.checked;
   
        await Promise.all(
            menu.pages.map((item) => {
                if(item.value === 'Y' && item.name !== value.target.name) return checked = true;    
            })
        )
      
        handleChangeCheckedItemMenu( checked, menu.name);
    };

    const handleChangeCheckedMenu = (event, values) => {

        handleChangeChecked(event);

        values.map((item) => {
            props.setValues((prevValues) => ({
                ...prevValues,
                [item.name]: (event.target.checked) ? 'Y' : 'N'
            }))
        });
    };

    const menus = [
        {
            title: 'Atendimentos', name: 'requests', value: props.values.requests, expand: handleExpandRequests, expanded: expandedRequests, 
            pages:  [
                        {title: 'Painel de Atendimentos', name: 'requests_panel', value: props.values.requests_panel},
                        {title: 'Lista de Atendimentos', name: 'requests_list', value: props.values.requests_list},
                        {title: 'Solicitações Frequentes', name: 'requests_fr', value: props.values.requests_fr},
                        {title: 'Gráficos de Atendimentos', name: 'requests_charts', value: props.values.requests_charts},
                        {title: 'Liberar Atendimento - Inadimplência', name: 'requests_release_client_defaulter', value: props.values.requests_release_client_defaulter},
                        {title: 'Liberar Atendimento - Hora Técnica', name: 'requests_release_client_technical_hour', value: props.values.requests_release_client_technical_hour},
                        {title: 'Finalizar Atendimentos', name: 'requests_finalize', value: props.values.requests_finalize},
                        {title: 'Trocar Cliente', name: 'requests_edit_client', value: props.values.requests_edit_client},
                    ]

        },
        {
            title: 'Servidor de Arquivos', name: 'files', value: props.values.files, expand: handleExpandFiles, expanded: expandedFiles, 
            pages:  [
                        {title: 'Arquivos', name: 'files_panel', value: props.values.files_panel},
                        {title: 'Categorias', name: 'files_categories', value: props.values.files_categories},          
                        {title: 'Editar todos os arquivos', name: 'files_edit_all', value: props.values.files_edit_all},          
                    ]

        },
        {
            title: 'Atualizações', name: 'actualizations', value: props.values.actualizations, expand: handleExpandActualizations, expanded: expandedActualizations, 
            pages:  [
                        {title: 'Clientes Conectados', name: 'actualizations_panel', value: props.values.actualizations_panel},
                        {title: 'Versões Sistemas', name: 'actualizations_files', value: props.values.actualizations_files},          
                        {title: 'Usuários', name: 'actualizations_users', value: props.values.actualizations_users},          
                    ]

        },
        {
            title: 'Base de Conhecimento', name: 'helpdesk', value: props.values.helpdesk, expand: handleExpandHelpdesk, expanded: expandedHelpdesk, 
            pages:  [
                        {title: 'Publicações', name: 'helpdesk_pubs', value: props.values.helpdesk_pubs},
                        {title: 'Imagens', name: 'helpdesk_images', value: props.values.helpdesk_images},
                        {title: 'Categorias', name: 'helpdesk_categories', value: props.values.helpdesk_categories},
                        {title: 'Cadastrar Publicações', name: 'helpdesk_create', value: props.values.helpdesk_create},
                        {title: 'Editar qualquer publicação', name: 'helpdesk_edit_all', value: props.values.helpdesk_edit_all},          
                    ]

        },
        {
            title: 'Administração', name: 'administration', value: props.values.administration, expand: handleExpandAdm, expanded: expandedAdm, 
            pages:  [
                        {title: 'Usuários', name: 'administration_users', value: props.values.administration_users},
                        {title: 'Grupos de Usuários', name: 'administration_users_groups', value: props.values.administration_users_groups},
                        {title: 'Clientes', name: 'administration_clients', value: props.values.administration_clients},
            
                    ]

        },        
    ]
  return (
    <div>
        <Dialog open={props.open} >
            <DialogTitle >Grupos de Usuários</DialogTitle>
            <DialogContent>
                <Box  sx={{float: "center", width: '100%', minWidth: '550px', mb: 2}} >
                    <TextField
                        autoFocus
                        name="description"
                        value={props.values?.description}
                        defaultValue=" "
                        label="Nome do Grupo"
                        type="text"
                        onChange={handleChangeValues}
                        fullWidth
                        variant="filled"
                    />  
                </Box>
                <Paper>
                <List sx={{ width: '100%', maxHeight: '400px',  bgcolor: 'background.paper', overflowY: 'scroll' }}>

                {
                    menus && menus.map((menu) => (
                        <Box sx={{width: '100%'}}>
                            <ListItemButton sx={{width: '100%'}}>
                                <ListItemIcon>
                                    <Checkbox 
                                        name={menu.name}
                                        checked={menu.value === 'Y'}
                                        onChange={(e) => handleChangeCheckedMenu(e, menu.pages)}
                                        inputProps={{ 'aria-label': 'controlled' }}                                                    
                                    />
                                </ListItemIcon>
                                <ListItemText primary={menu.title} />
                                {menu.expanded ? <ExpandLess onClick={menu.expand} /> : <ExpandMore onClick={menu.expand} />}
                            </ListItemButton>
                            {/* in={expandedMenuRequests} */}
                            <Collapse in={menu.expanded} timeout="auto" unmountOnExit sx={{ textAlign: "left", pl: 5}}> 
                                <List component="div" disablePadding>
                                    {menu.pages.map((item) => {
                                            return (
                                            <ListItemButton key={item.name}>
                                                <ListItemIcon>
                                                <Checkbox 
                                                    name={item.name}
                                                    checked={item.value === 'Y'}
                                                    onChange={(e) => handleChangeChecked(e, menu)}
                                                    inputProps={{ 'aria-label': 'controlled' }}                                                    
                                                />
                                                </ListItemIcon>
                                                <ListItemText primary={item.title} />
                                            </ListItemButton>
                                            )
                                        })}
                                    </List>
                            </Collapse>
                        </Box>
                    )) 
                }
                </List>
                </Paper>
            </DialogContent>
            <DialogActions sx={{paddingRight: 3}}>
                <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={() => handleClickSend()} variant="outlined" color="success">Salvar</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

export default UserGroup;