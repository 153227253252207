import React from 'react'
import Box from '@mui/material/Box';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import './publicationCard.css'
import EditIcon from '@mui/icons-material/Edit';
import { useCryptoData } from '../../../../hooks/useCryptoData/useCryptoData'; 

const CardPub = (props) => {
  const { decryptPermissionsTree } = useCryptoData();
  const user = decryptPermissionsTree(sessionStorage.getItem('data'));
  const handleOpenPub = () => {
    props.handleOpenPub(props.values.id, props.values.id_category, props.values.id_subcategory );
  }

  const handleEdit = () => {
    props.setValues(props.values);
    props.handleOpen();
  }
  return (
    <Box className='box-card-helpdesk' >
        <section >
          <Typography  
            className='title-pub-help'
            variant='h6' 
            color='primary'
            onClick={() => handleOpenPub()} >
            {props.values.title}
          </Typography> 
          <Box>
            <IconButton onClick={() => handleEdit()} 
              sx={{display: (props.admin && (props.values.user == user?.id || 
              (user?.id_group === 1 || user?.id_group === 5 ) )) ? '' : "none" }}
            >
              <EditIcon/>
            </IconButton>
          </Box>
        </section>
        <Typography variant="body2" >
          {props.values.description}           
        </Typography>  
        <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 1}}>
          <Typography fontSize={12} color="gray">
            Autor: {props.values.author}
          </Typography> 
          <Typography fontSize={12} color="gray" >
            Publicado em: {props.values.created_at}
          </Typography> 
        </Box> 
        <Divider sx={{mt: 2}} />    
    </Box>
  )
}

export default CardPub