import { SpeedDial, SpeedDialIcon, Typography } from '@mui/material';
import { Box, padding } from '@mui/system';
import React, { useState } from 'react';
import UsersAdmin from '../../../../components/administrative/users/users/Users';
import UsersTable from '../../../../components/administrative/users/usersTable/UsersTable';

const UsersPage = () => {
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [changed, setChanged ] = useState(false);

  const handleChanged = () => {
    setChanged(!changed);
  }

  const handleNewUser = () => {
    setEditing(false);
    setValues({});
    setOpen(true);
  }


  return (
    <div style={{padding: 20}}>
      <Box className="header-atendimento">
        <Typography variant="h4"  color="text.secondary" >
          Cadastro de Usuários
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Realize o cadastro, alteração ou exclusão de usuários.   
        </Typography>
      </Box>
      <Box>
        <UsersAdmin 
          open={open}
          setOpen={setOpen} 
          values={values}
          setValues={setValues}
          editing={editing}
          setEditing={setEditing}
          changed={changed}
          handleChanged={handleChanged}          
        />
      </Box>
      <div>
        <SpeedDial
              ariaLabel="Novo usuário"
              sx={{ position: 'fixed', bottom: 120, right: 16, }}
              icon={<SpeedDialIcon  />}
              onClick={() => handleNewUser()}
              >
        </SpeedDial>
      </div>
      <UsersTable
        open={open}
        setOpen={setOpen} 
        values={values}
        setValues={setValues}
        editing={editing}
        setEditing={setEditing}
        changed={changed}
        handleChanged={handleChanged}
        />
    </div>
  )
}

export default UsersPage;