import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, FormControl, IconButton, InputBase, InputLabel, MenuItem, Select } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';

//hooks 
import { useFetchData } from '../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../hooks/useMessages/useMessages';
import { FilesHeader } from './filesHeader';
import { Link } from 'react-router-dom';

let url = process.env.REACT_APP_API+ "/files";


export default function TableFiles(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({
    title: '',
    file: '',
    category: '',
  });
  const { ShowMessage, msgConfirm } = useShowMessages();
  const { data : listFiles} = useFetchData(url + '/getFiles', {title: filter.title, file: filter.file, category: filter.category, page: page, rowsPerPage: rowsPerPage}, props.changed, true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.handleChanged();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    props.handleChanged();
  };

  const handlerEdit = (values) =>{
    props.setValues(values);
    props.setFile({});
    props.setEditing(true);
    props.setOpen(true);
  }

  const getLinkDownload = (file_name, path) => {

    return `${url}/download?file_name=${encodeURI(file_name)}&path=${encodeURI(path)}`
   
  }

  function formatSize(size) {
    if (size < 1024) {
        return size + ' Bytes';
    } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' KB';
    } else if (size < 1024 * 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
        return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
  }

  return (
    <Box sx={{ width: '100%', height: '100%'}}>
      <Paper elevation={3} sx={{ p: 2, display: 'flex',  mb: 2, border: '1px solid rgba(0, 0, 0, 0)', ':hover': { border: '1px solid' } }}>
        <FilesHeader values={filter} setValues={setFilter} handleChanged={props.handleChanged} categories={props.categories} />
      </Paper>
      <Paper >
        <TableContainer sx={{ overflow: 'scroll', height: '70vh'}} >
          <Table stickyHeader  sx={{ width: '100%' }} >
            <TableHead>
              <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell>Categoria</TableCell>
                  <TableCell>Portado por</TableCell>    
                  <TableCell>Tamanho</TableCell>    
                  <TableCell align="center">Data</TableCell>     
                  <TableCell align='center'>Ações</TableCell>                                              
              </TableRow>
            </TableHead>
            <TableBody>
            {listFiles && listFiles.map((row) => (
              <TableRow key={row.id} sx={{height: '25px'}}>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell align="left">{row.categoryName}</TableCell>
                <TableCell align="left">{row.nameUser}</TableCell>
                <TableCell align="left">{formatSize(row.size)}</TableCell>
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="center">
                  <Button href={getLinkDownload(row.file_name, row.path)} target="_blank" variant="outlined" startIcon={<CloudDownloadIcon />} sx={{mr: 1}} color='success'>
                    Download
                  </Button>
                  <Button variant="outlined" startIcon={<VisibilityIcon />} onClick={() => handlerEdit(row)}>
                    Detalhes
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage='Registros por página'
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={(listFiles && listFiles) ? Math.ceil(listFiles[0]?.count) : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}