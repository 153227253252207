import { Alert, Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AppBarPrincipal from '../../../components/appBar/Appbar';
import './Home.css'
import { useState } from 'react'
import { NavLink } from 'react-router-dom';
import RequestClient from '../../../components/requests/requestsModais/requestClient/requestClient';
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';

const client = JSON.parse(sessionStorage.getItem('client'));

const Home = () => {

  const [openNewRequest, setOpenNewRequest] = useState(false);
  const [values, setValues] = useState({});

  const handleNavAtendimentos = () => {
 
  }

  const handleClickOpenRequest = () => {

    let newRequest = {
      sequence: null,
      description: '',
      status: 1,
      type_service: 30,
      type_service_description: 'Acesso Remoto',
      type_request: 0,
      hide_panel: 'N',
    };
    
    setValues(newRequest);   
    setOpenNewRequest(true);    
  }

  return (
    <div className='div-body-home' style={{  height: '100vh', width: '100%', backgroundImage: `url(/img/FUNDO.png)`, }}>
      <AppBarPrincipal/>  
      <Box className='div-body-items' >
        <Grid container spacing={1} columns={ 12 }  sx={{height: '100%', width: '100%', }} >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
            <Box className='div-text-welcome' >
              <Box sx={{ml: 5, mr: 5, mt: '70px', }}>
                <Typography variant="h4"  >
                  Olá, {client?.nome_fantasia}
                </Typography>
                <Typography variant="h5"   >
                  Bem vindo ao Atendimento Sirrus
                </Typography>
              </Box>
            </Box>
          </Grid> 
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
            <Box className='box-options-buttons' sx={{display: 'flex', justifyContent: 'center',width: '100%'  }}>
                  <Grid container spacing={2} columns={ 12 }  sx={{ height: '100%', width: '100%',  }} >
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3} marginBottom={1} sx={{height: '300px'}} >
                      <Box className='box-buttons-itens'>
                        <Box className='box-buttons-text'>  
                          <Typography variant='h5' sx={{mb: 2}}>Abertura de Atendimentos </Typography>  
                          <Typography sx={{mb: 2}}>
                            Através de nossa plataforma é possível realizar a abertura de um atendimento 
                            direto em nossos canais, evitando filas de whatsApp ou ligação.         
                          </Typography>
                        </Box>
                        <Box className='box-buttons-button'>
                          <Button 
                              variant="contained" color="success"
                              
                              onClick={handleClickOpenRequest} 
                              sx={{ width: 220, Height: 37 }} 
                            >
                            Novo Atendimento
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3} marginBottom={1} sx={{height: '300px'}} >
                      <Box className='box-buttons-itens'>
                        <Box className='box-buttons-text'> 
                          <Typography variant='h5' sx={{mb: 2}}>Acompanhe suas solicitações</Typography> 
                          <Typography sx={{mb: 2}}>
                              Confira o status, e a previsão de atendimento da suas solicitações, e também consulte atendimentos antigos.      
                          </Typography>
                        </Box>
                        <Box className='box-buttons-button'>
                          <Button 
                            color='primary'
                            variant="contained" 
                            sx={{ width: 220, Height: 37 }} 
                            onClick={() => handleNavAtendimentos()}
                            component={NavLink}
                            to='/atendimentos'
                          >
                            Acessar Atendimentos
                          </Button> 
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3} marginBottom={1} sx={{height: '300px'}} >
                      <Box className='box-buttons-itens'>
                        <Box className='box-buttons-text'> 
                          <Typography variant='h5' sx={{mb: 2}}>Acesso Remoto</Typography> 
                          <Typography sx={{mb: 2}}>
                            Baixe nossa ferramenta de acesso remoto, para que o nosso técnico possa acessar remotamente 
                            o seu computador.
                          </Typography>
                        </Box>
                        <Box className='box-buttons-button'>
                          <Button 
                              color='error'
                              variant="contained" 
                              href="https://download.anydesk.com/AnyDesk.exe?_ga=2.123987727.482375832.1532441846-1332168584.1532441846"
                              sx={{ width: 220, Height: 37 }}             
                          >
                            Baixar Anydesk 
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3} marginBottom={1} sx={{height: '300px'}} >
                      <Box className='box-buttons-itens'>
                        <Box className='box-buttons-text'>
                          <Typography variant='h5' sx={{mb: 2}}>Dúvidas Frequentes</Typography> 
                          <Typography sx={{mb: 2}} >
                            Acesse a Página de Manuais ou Base de Conhecimento, e verifique nossos materiais sobre as rotinas mais frequentes.
                          </Typography> 
                        </Box>
                        <Box className='box-buttons-button' sx={{ alignSelf: 'flex-end' }}>
                          <Button 
                              color='primary'
                              variant="contained" 
                              sx={{ width: 220, Height: 37, }} 
                              onClick={() => handleNavAtendimentos()}
                              component={NavLink}
                              to='/manuais'
                          >
                              Acessar Manuais
                            </Button>  
                        </Box>
                      </Box>
                    </Grid>  
                  </Grid>


                <RequestClient open={openNewRequest} values={values} setValues={setValues} setOpen={setOpenNewRequest}   admin={false}  requestsPage={false} />      
            </Box>
          </Grid> 
        </Grid>
      </Box>


    </div>
  )
}

export default Home