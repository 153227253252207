import { useCallback, useRef, useState } from "react"


export const useDebounce = () => {
    const timeouRef = useRef(null);
    


    const debounce = useCallback((interval, func = () => true ) => {
        
        clearTimeout(timeouRef.current);
        timeouRef.current = setTimeout(() =>  {

            func()

        }, interval); // 30 segundos
    }, [])
  return { debounce }
}

