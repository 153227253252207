import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, CardActions, Collapse, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShowSnackbars from '../../menssages/ShowSnackBar';

export default function CardManualYoutube(props) {
    const [expanded, setExpanded] = React.useState(false); 

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };    


    const handleOpenVideo = () => {
      props.setVideo(props.values.id.videoId);
      props.setOpen(true)
    }

    const copyToClipBoard = async () => {
      await navigator.clipboard.writeText(`https://youtu.be/${props.values.id.videoId}`);
      showMsg('success', 'Copiado para área de transferência') 
    }  

    const [msg, setMsg] = React.useState({
      open: false,
      text: '',
      type: 'success'
    });
  
    const showMsg = (type, msg) => {
      setMsg({
        open: true,
        text: msg,
        type: type
      })
    }

  return (
    <Card sx={{ maxWidth: 345 }} >
      <ShowSnackbars msg={msg} setMsg={setMsg}/>
      <CardActionArea onClick={handleOpenVideo}>
        <CardMedia
          component="img"
          height="140"
          image={props.values.snippet.thumbnails.high.url}
          
        />
        <CardContent sx={{maxHeight: 60}} >
          <Typography fontSize={16} variant="h6" component="div" align="justify" overflow= "hidden"  textOverflow= "ellipsis">
            {props.values.snippet.title}
          </Typography>

        </CardContent>        
      </CardActionArea>
      <CardActions disableSpacing>
        <IconButton aria-label="share" onClick={() => copyToClipBoard()}>
          <ShareIcon />
        </IconButton>
        <Box
          sx={{paddingLeft: "70%",  display: "",  width: "90%"}}
        >
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        </Box>
      </CardActions>      
      <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ textAlign: "left"}}>
        <CardContent>
        <Typography variant="body2" color="text.secondary" >
            {props.values.snippet.description}
          </Typography>  
        </CardContent>
      </Collapse>      
    </Card>
  );
}