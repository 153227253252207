import { Button, Checkbox, FormControl, Grid, InputLabel, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, {useState} from 'react'
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import SendIcon from '@mui/icons-material/Send';
import { useShowMessages } from '../../../hooks/useMessages/useMessages';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
const url = process.env.REACT_APP_API;
const urlAtualizacao = process.env.REACT_APP_API_ATUALIZACAO;

const ClientsActualizations = (props) => {
    const { data: values, loading, setLoading, editData, postData } = useFetchData((url+'/liberacao/modulos/'), {id: props.client?.id_gerente}, props.client?.id_gerente);
    const { ShowMessage } = useShowMessages();
    const [systems, setSystems] = useState([
        {
            id: 1,
            id_gerente: 1,
            name: 'Sirrus Completo - exe'
        },
        {
            id: 4,
            id_gerente: 2,
            name: 'Sirrus Ponto - exe'
        }
    ]);
    const [modules, setModules] = useState([
        {
            id: 2,
            id_gerente: 46,
            name: 'Módulo Restaurante - exe'
        },
        {
            id: 3,
            id_gerente: 19,
            name: 'Módulo PDV - exe'
        },
        {
            id: 6,
            id_gerente: '',
            name: 'Módulo Vendas - exe'
        },
        {
            id: 7,
            id_gerente: 9,
            name: 'Módulo OS Oficina - exe'
        },
        {
            id: 8,
            id_gerente: '',
            name: 'Módulo NFe/NFCe - exe'
        },
    ]);
    const [folders, setFolders] = useState([
        {
            id: 10,
            name: 'Emissão NFe - Schemas'
        },
        {
            id: 11,
            name: 'Emissão NFe - Arquivos'
        },
    ]);


    const handleChangeCheckedSystems = async (value, id) => {
        const nextSystems = systems.map((s) => {
            if (s.id === id) {
              let system = s;
              system.checked = value.target.checked;
              return system;
            } else {
              return s;
            }
          });
          setSystems(nextSystems);
    };

    const handleChangeCheckedModules = async (value, id) => {
        const nextModules = modules.map((m) => {
            if (m.id === id) {
              let module = m;
              module.checked = value.target.checked;
              return module;
            } else {
              return m;
            }
          });
          setModules(nextModules);
    };

    const handleChangeCheckedFolders = async (value, id) => {
        const nextFolders = folders.map((f) => {
            if (f.id === id) {
              let folder = f;
              folder.checked = value.target.checked;
              return folder;
            } else {
              return f;
            }
          });
          setFolders(nextFolders);
    };

  return (
    <Grid container spacing={1} columns={ 12 } sx={{float: "center", minWidth: '550px', display: (props.tab === 1) ? '' : 'none'}} >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Sistemas e Módulos lançados  
            </Typography>
            {
              systems && systems?.filter((system) => system?.id_gerente === values?.liberacao?.sistema_id).map((system) => {
                    return <ListItemButton key={system.id}>
                                <ListItemIcon>
                                    <Checkbox 
                                        name={system.name}
                                        checked={true}
                                        inputProps={{ 'aria-label': 'controlled' }}                                                    
                                    />
                                </ListItemIcon>
                                <ListItemText primary={system.name} />
                            </ListItemButton>   
                })
            }
            {
                modules && modules?.filter((module) => values?.modulos?.find((m) => m?.id_modulo === module.id_gerente)).map((module) => {
                    if(values?.modulos?.find((m) => m?.id_modulo === module.id_gerente)){
                        return <ListItemButton key={module.id}>
                            <ListItemIcon>
                                <Checkbox 
                                    name={module.name}
                                    checked={true}
                                    inputProps={{ 'aria-label': 'controlled' }}                                                    
                                />
                            </ListItemIcon>
                            <ListItemText primary={module.name} />
                        </ListItemButton> 
                    }  
                })
            }  
            {
            values?.liberacao?.sistema_id === 1 && folders && folders?.map((folder) => {
                    return  <ListItemButton key={folder.id}>
                                <ListItemIcon>
                                    <Checkbox 
                                        name={folder.name}
                                        checked={true}
                                        onChange={(e) => handleChangeCheckedFolders(e, folder.id)}
                                        inputProps={{ 'aria-label': 'controlled' }}                                                    
                                    />
                                </ListItemIcon>
                                <ListItemText primary={folder.name} />
                            </ListItemButton> 
                })
            }                                     
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1} sx={{display: 'flex', justifyContent: 'center'}}>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Dados de Configuração
            </Typography> 
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="contract"
                value={props.client?.contract}
                defaultValue=" "
                label="Usuário"
                type="text"
                fullWidth
                disabled={true}                
              />                              
        </Grid> 
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="contract"
                value={props.client?.cpf_cnpj}
                defaultValue=" "
                label="Senha"
                type="text"
                fullWidth
                disabled={true}                
              />                              
        </Grid> 
        <Grid item xs={12} md={4}  marginBottom={1} marginTop={1}>
            <FormControl fullWidth >
            <InputLabel id="bkp-label">Modo Backup</InputLabel>
            <Select
                labelId="bkp-label"
                label="Status"
                name="status"
                value={props.client?.type_bkp}
                inputProps={{ readOnly: true }}
            >
                <MenuItem value={''}><em>Não Informado</em></MenuItem>
                <MenuItem value={0}><em>API Backup</em></MenuItem>
                <MenuItem value={1}><em>Dropbox</em></MenuItem>
            </Select>
            </FormControl>
        </Grid>  
        <Grid item xs={12} md={4}  marginBottom={1} marginTop={1}>
            <FormControl fullWidth >
            <InputLabel id="api-label">Possuí API Configurada</InputLabel>
            <Select
                labelId="api-label"
                label="Possuí API Configurada"
                name="status"
                value={props.client?.actualization_is_active}
                inputProps={{ readOnly: true }}
            >
                <MenuItem value={'N'}><em>Não</em></MenuItem>
                <MenuItem value={'Y'}><em>Sim</em></MenuItem>
            </Select>
            </FormControl>
        </Grid> 
        <Grid item xs={12} md={4}  marginBottom={1} marginTop={1}>
            <FormControl fullWidth >
            <InputLabel id="api-label">Cadastrado na API</InputLabel>
            <Select
                labelId="api-label"
                label="Possuí API Configurada"
                name="status"
                value={props.client?.in_actualization_api}
                inputProps={{ readOnly: true }}
            >
                <MenuItem value={'N'}><em>Não</em></MenuItem>
                <MenuItem value={'Y'}><em>Sim</em></MenuItem>
            </Select>
            </FormControl>
        </Grid>   
        <Grid item xs={12} md={12}>
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker 
                fullWidth
                sx={{width: '100%'}}
                label={'Data do último backup'} 
                ampm={false} 
                openTo="day" 
                name="data_last_bkp"
                value={dayjs.tz(props.client?.data_last_bkp)}
                disabled={true}
                format="DD/MM/YYYY HH:mm" 
                />
            </LocalizationProvider>
        </Grid>
    </Grid>
  )
}

export default ClientsActualizations;