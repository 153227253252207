import axios from 'axios';
import { useEffect, useState } from 'react'
import { useShowMessages } from '../useMessages/useMessages';

const useFetchData = (url, params = {}, changed, admin, atualizacoes, keepData) => {
    const [data, setData] = useState([]);
    const [msg, setMsg] = useState('');
    const [error, setError] = useState([]);
    const { ShowMessage } = useShowMessages();
    const [loading, setLoading] = useState(false);
    const handleSignOut = () => {
      sessionStorage.clear(); 
      window.location.reload(false);
    }

    const type =  (admin) ? 'user' : 'client';
    const token = (admin) ? sessionStorage.getItem('userToken') : sessionStorage.getItem('clientToken');

    let Authorization = JSON.stringify({
      token: token,
      type: type,
    });

    if(atualizacoes){
      Authorization = `Bearer ${sessionStorage.getItem('userTokenAtt')}`;
    }
    
     useEffect( () => {
        if(!keepData)setData([]);
        setLoading(true);
         axios.get(url, { 
          params:  params,
          headers: {'Authorization': Authorization}
        })
        .then((response) => {
          setData(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            if(error.response.status === 401){
              // ShowMessage('warning', 'Sessão Expirada', 'Você será direcionado para a tela de login!')
              // .then(() => {handleSignOut()});
            }
            setError(error);
          }
        }) 
        .finally(() => {
          setLoading(false); 
        })
      }, [url, changed]);

      const getData = async(url, params) => {
        setLoading(true);
        await axios.get(url, { params:  params})
        .then((response) => {
          return setData(response.data);
        })
       .catch(function (error) {
         if (error.response) {
           if(error.response.status === 401){
           }
           console.log(error)
           return  setError(error);
         }
       })
       .finally(() => {
        setLoading(false);
       }) 
      }

      const getDataDirectly = async(urlGet, paramsGet, callback) => {
        setLoading(true);
        await axios.get(urlGet, { params:  paramsGet})
        .then((response) => {
         return callback(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            if(error.response.status === 401){
            }
            return  setError(error);
            console.log(error)
          }
        })
        .finally(() => {
          setLoading(false);
        }) 
      };
      
      const getDataDirectlyPost = async(urlGet, data, callback) => {
        setLoading(true);
        await axios.post(urlGet, data)
        .then((response) => {
         return callback(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            if(error.response.status === 401){
            }
            return  setError(error);
            console.log(error)
          }
        })
        .finally(() => {
          setLoading(false);
        }) 
      }    

      const editData = async(url, data) => {
            setLoading(true);
            return await axios.put(url, data)
            .then((response) => {
              return({
                success: true,
                msg: response.data.message
              })
            })
            .catch((error) => {
              if(error.response.data) {
                return({  success: false, msg: error.response.data.message })
              } 
              else {return({ success: false, msg: 'Ocorreu um erro ao salvar' })}              
            }) 
            .finally(() => {
              setLoading(false);
            }) 
      }

      

    const postData = async(url, data) => {
      setLoading(true);
      return await axios.post(url, data, { 
        headers: {'Authorization': Authorization}
      })
      .then((response) => {
        setMsg(response.message);
        console.log(response.data)
        return({
          success: true,
          msg: response.data.message,
          id: response.data.results.insertId,
          data: response.data
        })
      })
      .catch(function (error) {
        console.log(error.response)
        if(error.response.data.message) {
          return({
            success: false,
            msg: error.response.data.message
          })
        } else {
          return({
          success: false,
          msg: 'Ocorreu um erro ao salvar'
        })}
      }) 
      .finally(() => {
        setLoading(false); 
      })
  }    

  const deleteData = async(url, params) => {
    setLoading(true);
    return await axios.delete(url, { params: params })
    .then((response) => {
      setMsg(response.message);
      return({
        success: true,
        msg: 'Registro deletado com sucesso'
      })
    })
    .catch(function (error) {
      console.log(error.response)
      if(error.response.data.message) {
        return({
          success: false,
          msg: error.response.data.message
        })
      } else {
        return({
        success: false,
        msg: 'Ocorreu um erro ao salvar'
      })}
    }) 
    .finally(() => {
      setLoading(false);
    })
}   

  return {data, error, editData, postData, deleteData, msg, getData, getDataDirectly, loading, getDataDirectlyPost}

}

export {useFetchData}