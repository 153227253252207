import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

//Hooks
import {useFetchData} from '../../../../hooks/useFetchData/useFetchData';

let url = process.env.REACT_APP_API+ "/user/groups";


export default function GroupsTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState('');

  const { data : listGroups } = useFetchData(url, {}, props.changed);
   
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.handleChanged();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    props.handleChanged();
  };

  const handleEditGroup = (values) =>{
    props.setValues(values);
    props.setEditing(true);
    props.setOpen(true);
  }

  const handleSetFilter = (filter) => {
    setFilter(filter.target.value);
  }

  const handleSearch = () => {
    props.handleChanged();
  }  
 
  return (
    <Box>
      <Paper elevation={3} sx={{ p: '2px 4px', display: 'flex',  mb: 2, border: '1px solid rgba(0, 0, 0, 0)', ':hover': { border: '1px solid' } }}>
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Busque por Nome, Usuário ou Código"
            value={filter}
            onChange={handleSetFilter}
            onKeyDown={(e) => {if(e.key === "Enter")handleSearch()}}
        />
        <IconButton type="button" sx={{ p: '10px' }} title='Buscar' onClick={handleSearch}>
            <SearchIcon />
        </IconButton>
      </Paper >      
      <Paper sx={{ width: '100%', overflow: 'hidden' }}> 
        <TableContainer sx={{ overflow: 'scroll', height: '70vh'}}>
          <Table stickyHeader  sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Nome</TableCell> 
                  <TableCell align="center">Editar</TableCell>                                              
              </TableRow>
            </TableHead>
            <TableBody>
            {listGroups?.map && listGroups.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" >
                  {row.id}
                </TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleEditGroup(row)}>
                      <EditOutlinedIcon />
                  </Button></TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage='Registros por página'
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={(listGroups && listGroups) ? listGroups.length : 10}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}