import CryptoJS from 'crypto-js';

let encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

export const useCryptoData = () => {

    // Função para criptografar a árvore de permissões
    function encryptPermissionsTree(permissionsTree) {
        if(!permissionsTree) return {};
        const jsonString = JSON.stringify(permissionsTree);
        const encrypted = CryptoJS.AES.encrypt(jsonString, encryptionKey).toString();
        return encrypted;
    }
  
  // Função para descriptografar a árvore de permissões
    function decryptPermissionsTree(encryptedData) {
        if(!encryptedData) return;
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
        const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
        const permissionsTree = JSON.parse(decryptedString);
        return permissionsTree;
    }

    return{encryptPermissionsTree, decryptPermissionsTree}

};


