import { Fragment } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
// Paginas
import Atendimentos from '../pages/client/pageRequests/Atendimentos';
import Home from '../pages/client/pageHome/Home';
import LoginPageAdmin from '../pages/user/usersLoginPage/Login';
import Manuals from '../components/manuais/ListagemManuais/manuals';
import LoginPage from '../pages/client/pageLoginClients/Login';
import MainPage from '../pages/user/mainPage/mainPage';
import PublicationsPage from '../components/helpdesk/publications/publicationsPage/PublicationsPage';
import PageVisualizarManual from '../components/manuais/PageVisualizarManual/PageVisualizarManual';


const PrivateClient =  ({ Item }) => {
  const signed = sessionStorage.getItem('clientToken') != null;
  return signed ? <Item /> : < LoginPage />;
}

const PrivateAdmin =  ({ Item }) => {
  const signed = sessionStorage.getItem('userToken') != null 
  return signed ? <Item /> : < LoginPageAdmin />;
}

export const RoutesDefault = () => {
  return (
      <BrowserRouter>      
        <Fragment>    
          <Routes>
              <Route exact path='/home' element={<PrivateClient Item={Home} />} />;
              <Route path='/manuais' element={<PrivateClient Item={Manuals}  />} />;
              <Route path='/atendimentos' element={<PrivateClient Item={Atendimentos} />} />;
              <Route path='/login' element={<LoginPage/>} />;
              <Route path='*' element={<LoginPage/>} />;
              <Route path='/:client' element={<LoginPage/>} />;
              <Route path='/h' element={< PrivateClient Item={PublicationsPage} />} />;
              <Route path='/helpdesk/pub' element={< PrivateClient Item={PublicationsPage} />} />;
              <Route path='/manual/vizualizar' element={<PageVisualizarManual/>} />;    
              <Route path='/manual/visualizar' element={<PageVisualizarManual/>} />;   

              {/* Rotas de administração */}
              <Route exact path='/admin' element={<PrivateAdmin Item={MainPage} />}/>;
              <Route exact path='/admin/:componentUrl' element={< PrivateAdmin Item={MainPage}/>}/>;

              {/* <Route path='/admin/atendimentos' element={<AtendimentosAdmin/>}/>; */}
              
            </Routes>
        </Fragment>
      </BrowserRouter>
  )
}

export default RoutesDefault;
