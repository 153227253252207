import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Manaus');

let url = process.env.REACT_APP_API+ "/requests";
const IsDev = process.env.REACT_APP_AMBIENTE_DEV === 'true';
const RequestConclude = (props) => {
    const [disabledButtons, setDisabledButtons] = useState(false);
    const { editData } = useFetchData(url);
    const { ShowMessage } = useShowMessages();

    const handleChangeValues = (value) => {  
        props.setValues((prevValues) => ({
          ...prevValues, 
          [value.target.name] : value.target.value,
        }));
    };

    const handlerChangeDate= (value, field) => {  
      props.setValues((prevValues) => ({
        ...prevValues, 
        [field]: value
      }));
    };

    const handleClose = () => {
      props.handleChanged();
      props.setOpen(false);
    }

      const sendValues = async (values) => {
        

        values.start_date = dayjs.tz(props.values?.start_date);
        values.conclusion_date = dayjs.tz(props.values?.conclusion_date);
        
        values.start_date = dayjs(values?.start_date).utc(true);
        values.conclusion_date = dayjs(values?.conclusion_date).utc(true);
        
        editData(url, values).then((res) => {
            if(res.success){
              ShowMessage('success', 'Bom trabalho',  'Você finalizou seu atendimento com sucesso!'); 
              props.handleChanged();
              handleClose();
            } else {
              ShowMessage('error', res.msg);
            }
          });
      }

      const handleClickSend = async () => {

        if(!props.values.conclusion){
            ShowMessage('error', 'Preencha todos os campos', 'Informe a baixa do atendimento.')    
            return;
        }
        if(!props.values.conclusion_date){
            ShowMessage('error', 'Preencha todos os campos', 'Informe a data de conclusão.')    
            return;
        }

        sendValues(props.values);
      }

  return (
    <Dialog open={props.open}>
        <DialogTitle>
            Informe os dados da baixa.
        </DialogTitle>
        <DialogContent >    
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker 
                fullWidth
                sx={{width: '100%', mt: 1, mb: 1}}
                label={'Iniciado em:'} 
                ampm={false} 
                openTo="day" 
                name="start_date"
                value={dayjs.tz(props.values?.start_date)}
                onChange={(e) => handlerChangeDate(e, 'start_date')}
                format="DD/MM/YYYY HH:mm" 
              />
            </LocalizationProvider>                        
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                fullWidth
                sx={{width: '100%', mt: 1, mb: 1}}
                label={'Concluído em:'} 
                ampm={false} 
                openTo="day" 
                name="conclusion_date"
                value={dayjs.tz(props.values?.conclusion_date)}
                onChange={(e) => handlerChangeDate(e, 'conclusion_date')}
                format="DD/MM/YYYY HH:mm" 
              />
            </LocalizationProvider>  
            <TextField sx={{mt: 1}}
                label="Baixa do Atendimento"
                multiline
                fullWidth                
                name='conclusion'
                defaultValue={props?.values?.conclusion}
                onBlur={(e) => handleChangeValues(e)}
                placeholder='Informe a baixa do atendimento'
                variant="outlined"     
            /> 
        </DialogContent>
        <DialogActions>
        <Button disabled={disabledButtons} onClick={handleClose} variant="outlined" color='error'>Cancelar</Button>
            <Button 
              disabled={disabledButtons} 
              onClick={() => handleClickSend()} 
              variant="outlined" 
              color="success"       
              >
                Concluir
            </Button>
        </DialogActions>
    </Dialog>
  )
}

export default RequestConclude;