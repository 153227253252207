import React, { useState } from 'react';
import { Autocomplete,  Badge,  Box,  Button,  Checkbox,  Dialog,  DialogContent,  DialogTitle,  FormControl,  FormControlLabel,  Grid,  IconButton,  Input,  InputAdornment,  InputBase,  InputLabel,  MenuItem,  OutlinedInput,  Paper,  Select,  TextField,  } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../hooks/useMessages/useMessages';
import FilterListIcon from '@mui/icons-material/FilterList';

let urlUsers = process.env.REACT_APP_API+ "/user/all";
let urlGroups = process.env.REACT_APP_API+ "/user/groups";

export const RequestsHeader = (props) => {
    const [changed, setChanged] = useState(false);
    const { data : listUsers } = useFetchData(urlUsers);
    const { data : listGroups } = useFetchData(urlGroups);
    const [values, setValues] = useState(props?.values);
    const { ShowMessage } = useShowMessages();
    const [openFilter, setOpenFilter] = useState(false);

  
    const handleCloseFilter = () => {
      setOpenFilter(false);
    }

    const handleOpenFilter = () => {
      setOpenFilter(true);
    }
  
  
    const handleChanged = () => {
      setChanged(!changed);
    }

    const handleChangeType = (values) => {
      setValues((prevValues) => (
        {
            ...prevValues, "type": (!values) ? '' : values.target.value,
      })); 

      sessionStorage.setItem('typeFilter', (!values) ? 'user' : values.target.value);
      handleChanged();
    }

    const handleChangeUser = (values) => {
        setValues((prevValues) => (
          {
              ...prevValues, id: (!values) ? null : values.id,
        }));  
        setValues((prevValues) => (
          {
              ...prevValues, "name": (!values) ? '' : values.name,
        })); 
        sessionStorage.setItem('userFilterId', (!values) ? null : values.id)     
        sessionStorage.setItem('userFilterName', (!values) ? null : values.name)  
    }

    const handleChangeGroup = (values) => {

      setValues((prevValues) => (
        {
            ...prevValues, 'idGroup': (!values) ? null : values.id,
      }));  
      setValues((prevValues) => (
        {
            ...prevValues, "nameGroup": (!values) ? '' : values.description,
      })); 

      sessionStorage.setItem('groupFilterId', (!values) ? null : values.id)     
      sessionStorage.setItem('groupFilterName', (!values) ? null : values.description)  
    }

    const handleSetFilter = (value) => {
      setValues((prevValues) => ({
        ...prevValues,
        'filter': value.target.value,
      }));
      sessionStorage.setItem('requestsFilter', (!values) ? null : value.target.value)
    }

    const handleChangeOrder = (value) => {
      setValues((prevValues) => ({
        ...prevValues,
        'order': value.target.value,
      }));
      sessionStorage.setItem('requestsOrderBy', (!values) ? 's.created_at' : value.target.value)
    }

    const handleChangeChecks = (value) => {
      setValues((prevValues) => ({
        ...prevValues,
        'ShowHiddenRequests': (value.target?.checked) ? 'Y' : 'N',
      }));
      sessionStorage.setItem('show_hidden_requests', (value.target?.checked) ? 'Y' : 'N')      
    }

    
    const handleChangePriority = (value) => {
      setValues((prevValues) => ({
        ...prevValues,
        'priority': value.target.value,
      }));
      sessionStorage.setItem('requestsPriority', (!values) ? 5 : value.target.value)
    }


    const handleSearch = () => {

      if(!values?.id){
        ShowMessage('error', 'Informe o atendente', 'Informe o usuário mesmo filtrando por setor')    
        return;
      } 
      props.setValues(values);
      props.handleChanged();

    }

  return (
        <Box sx={{display: 'flex', m: 2,}}>
          <Grid container spacing={1} columns={ 12 } sx={{float: "center", justifyContent: 'space-between', }} >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{display: 'flex'}}>
              <FormControl variant="outlined" sx={{  minWidth: 120, mr: 1 }}>
                <Select
                  labelId="type"
                  id="demo-simple-select-standard"
                  value={values?.type}
                  onChange={handleChangeType}
                >
                  <MenuItem value={'user'}>Usuário</MenuItem>
                  <MenuItem value={'groups'}>Setor</MenuItem>
                </Select>
              </FormControl>
              {values?.type === 'user'
                ?
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={listUsers}
                  getOptionLabel={ (option) => option['name'] || option }    
                  value={values?.name}
                  onChange={(event, values) => handleChangeUser(values)}
                  renderInput={(params) => 
                  <TextField
                    name="user"
                    {...params} label="Atendente" 
                  />}
                />
                :
                <Autocomplete
                  disablePortal
                  fullWidth
                  options={listGroups}
                  getOptionLabel={ (option) => option['description'] || option }    
                  value={values?.nameGroup}
                  onChange={(event, values) => handleChangeGroup(values)}
                  renderInput={(params) => 
                  <TextField
                    name="group"
                    {...params} label="Setor" 
                  />}
                />
              }

            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{display: 'flex'}}>
              <FormControl sx={{mr: 2}} fullWidth variant="outlined">
                <InputLabel htmlFor="filter">Filtro</InputLabel>
                <OutlinedInput
                  id="filter"
                  placeholder="Filtrar Atendimentos"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton type="button" sx={{ p: '10px' }} title='Opções' onClick={handleOpenFilter}>
                        <FilterListIcon />       
                      </IconButton>   
                    </InputAdornment>
                  }
                  label="Filtro"
                  value={values.filter}
                  onChange={handleSetFilter}
                  onKeyDown={(e) => {if(e.key === "Enter")handleSearch()}}
                />
              </FormControl>
              <Button 
                variant='contained' 
                onClick={handleSearch}
                sx={{ height:'55px'}}
              >
                <SearchIcon/>
              </Button>  
            </Grid>
          </Grid>
          <Dialog open={openFilter} onClose={handleCloseFilter}> 
            <DialogTitle>Filtrar Atendimentos</DialogTitle>
            <DialogContent>
            <Grid container spacing={1} columns={ 12 } sx={{float: "center", m: 1, width: 300}} >
              <Grid item xs={12} md={12}  sx={{mb: 1}} >
                  <FormControl fullWidth>
                  <InputLabel id="order-label">Ordem</InputLabel>
                  <Select
                    labelId="order-label"
                    label="Ordem"
                    fullWidth
                    name="order"
                    value={values.order}
                    onChange={handleChangeOrder}
                  >
                    <MenuItem value={'s.created_at'}><em>Data de Abertura</em></MenuItem>
                    <MenuItem value={'s.start_date'}><em>Previsão para Atendimento</em></MenuItem>
                  </Select>
                </FormControl>                                 
              </Grid>
              <Grid item xs={12} md={12}  sx={{mb: 1}} >
                  <FormControl fullWidth>
                  <InputLabel id="priority-label">Prioridade</InputLabel>
                  <Select
                    labelId="priority-label"
                    label="Prioridade"
                    fullWidth
                    name="priority"
                    value={values.priority}
                    onChange={handleChangePriority}
                  >
                    <MenuItem value={1}><em>Lista Padrão</em></MenuItem>
                    <MenuItem value={2}><em>Lista Vip</em></MenuItem>     
                    <MenuItem value={5}><em>Todos</em></MenuItem>
                  </Select>
                </FormControl>                                 
              </Grid>   
              <FormControlLabel
                        control={
                          <Checkbox 
                            name={'show_hidden_requests'}
                            checked={values.ShowHiddenRequests === 'Y'} 
                            onChange={handleChangeChecks}
                            inputProps={{ 'aria-label': 'controlled' }} 
                            
                          />
                        }
                        label='Listar Atendimentos Ocultos'
              />            
            </Grid>
            </DialogContent>
          </Dialog>
        </Box>
  );
    
}