import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
let url = process.env.REACT_APP_API+ "/user/";
let urlGroups = process.env.REACT_APP_API+ "/user/groups";

const UsersAdmin = (props) => {
  const { editData, postData } = useFetchData(url);
  const { data : listGroups } = useFetchData(urlGroups, {}, props.changed);
  const { ShowMessage } = useShowMessages();

  const handleChangeValues = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      [value.target.name] : value.target.value,
    }));

  };

  const handleChangeChecks = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      [value.target.name] : (value.target?.checked) ? 'Y' : 'N',
    }));
  }

  const handleClose = () => {
    props.setOpen(false)
  }

  const handleClickSend = async () => {
    const res =  (!props.editing) ? await postData(url, props.values) : await editData(url, props.values);
    if(res.success){
      ShowMessage('success', res.msg);
      handleClose();
    }else{

      ShowMessage('error', res.msg);

    } 
  }

  const errPassword =( props.values.password != props.values.password_confirm); 

  return (
    <div>  
      <Dialog open={props.open}>
        <DialogTitle >Cadastro de usuários</DialogTitle>
        <DialogContent>
          <Grid container spacing={1} columns={ 12 } sx={{float: "center",}} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
              <TextField
                className='label-MovtAtendimento-admin'
                autoFocus
                name="name"
                value={props.values.name}
                defaultValue=" "
                label="Nome Completo"
                type="text"
                onChange={handleChangeValues}
                fullWidth
                variant="filled"
              />                              
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="user"
                value={props.values.user}
                defaultValue=" "
                label="Nome de usuário"
                type="text"
                onChange={handleChangeValues}
                fullWidth
                variant="filled"
              />                              
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="CPF"
                value={props.values?.CPF}
                defaultValue=" "
                label="CPF"
                type="number"
                onChange={handleChangeValues}
                fullWidth
                variant="filled"
              />                              
            </Grid> 
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
              <TextField
                className='label-MovtAtendimento-admin'
                autoFocus
                name="email"
                value={props.values.email}
                defaultValue=" "
                label="Email"
                type="text"
                onChange={handleChangeValues}
                fullWidth
                variant="filled"
              />                              
            </Grid>           
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} marginBottom={1} marginTop={1}>
              <TextField
                fullWidth
                id="filled-password-input"
                label="Senha"
                type="password"
                name='password'
                autoComplete="current-password"
                variant="filled"
                value={props.values.password}
                onChange={handleChangeValues}
                /> 
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} marginBottom={1} marginTop={1}>
              <TextField
                fullWidth
                error={errPassword}
                helperText={(!errPassword) ? '' : 'As senhas não conferem'}
                id="filled-password-input"
                label="Confirme"
                type="password"
                name='password_confirm'
                autoComplete="current-password"
                variant="filled"
                value={props.values.password_confirm}
                onChange={handleChangeValues}
                /> 
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6} marginBottom={1} marginTop={1}>
              <FormControl fullWidth >
                <InputLabel id="func-label">Grupo</InputLabel>
                <Select
                  
                  labelId="func-label"
                  label="Grupo"
                  name="id_group"
                  value={props.values.id_group}
                  onChange={handleChangeValues}
                  // inputProps={{ readOnly: true }}
                >                 
                {
                  
                  listGroups.map && listGroups.map((g) => (
                    <MenuItem value={g.id}><em>{g.description}</em></MenuItem>
                  ))
                }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={3} marginBottom={1} marginTop={1}>
              <FormControl fullWidth >
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  label="Status"
                  name="status"
                  value={props.values.status}
                  onChange={handleChangeValues}
                  // inputProps={{ readOnly: true }}
                >
                  <MenuItem value={0}><em>Inativo</em></MenuItem>
                  <MenuItem value={1}><em>Ativo</em></MenuItem>
                </Select>
              </FormControl>
            </Grid>            
            <Grid item xs={12} sm={12} md={12} lg={12} xl={3} marginBottom={1} marginTop={1}>
              <TextField
                fullWidth
                label="Código Interno"
                type="number"
                name='id_gerente'
                variant="filled"
                value={props.values.id_gerente}
                onChange={handleChangeValues}
                /> 
            </Grid>    
            <Grid item xs={12} sx={{textAlign: 'left', }}>
              <FormControlLabel
                control={
                  <Checkbox 
                    name={'ignore_completion_time'}
                    checked={props.values?.ignore_completion_time === 'Y'} 
                    onChange={handleChangeChecks}
                    inputProps={{ 'aria-label': 'controlled' }} 
                    
                  />
                }
                label='Conclusão Automática'
              /> 
            </Grid>          
            <Grid item xs={12} sx={{textAlign: 'left', }}>
              <FormControlLabel
                control={
                  <Checkbox 
                    name={'files_edit_all'}
                    checked={props.values?.files_edit_all === 'Y'} 
                    onChange={handleChangeChecks}
                    inputProps={{ 'aria-label': 'controlled' }} 
                    
                  />
                }
                label='Editar todos os Arquivos'
              /> 
            </Grid>          
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingRight: 3}}>
          <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
          <Button onClick={() => handleClickSend()} variant="outlined" color="success">Salvar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UsersAdmin;