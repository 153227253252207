import React, { useRef, useEffect } from 'react'
import JoditEditor from 'jodit-react';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogTitle, FormControl, Grid,  SpeedDial, SpeedDialIcon, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import { useCryptoData } from '../../../../hooks/useCryptoData/useCryptoData'; 

let url = process.env.REACT_APP_API+'/helpdesk/publications';


const CriarPub = (props) => {
    const { decryptPermissionsTree } = useCryptoData();
    const user = decryptPermissionsTree(sessionStorage.getItem('data'));
    const editor = useRef(null);
    const [checked, setChecked] = React.useState(props.values.admin === "Y");


    const { ShowMessage } = useShowMessages();
    useEffect( () => {
      setChecked((props.values.admin === "Y"));
    }, [props.values.admin])

    const handleChangeAdmin = (event) => {
      props.setValues((prevValues) => (
        {
            ...prevValues, "admin": (event.target.checked) ? "Y" : "N",
      }
    ))
      setChecked(event.target.checked)
    };

    const handleChangeCategory = (values) => {
      props.setValues((prevValues) => (
        {
            ...prevValues, "id_category": (!values) ? '0' : values.id,
      }));
      props.setValues((prevValues) => (
        {
            ...prevValues, "category": (!values) ? '' : values.name,
      }));      

    }

    const handleChangeSubCategory = (values) => {
      props.setValues((prevValues) => (
        {
            ...prevValues, "id_subcategory": (!values) ? '0' : values.id,
      }));
      props.setValues((prevValues) => (
        {
            ...prevValues, "subcategory": (!values) ? '' : values.name,
      }));      
    }

    const handleChangeValues = (value) => {
        props.setValues((prevValues) => (
            {
                ...prevValues, [value.target.name]: value.target.value,
            }
        ))
    }



    const HandleContent = (event) => {

      props.setValues((prevValues) => (
        {
            ...prevValues, "content": event,
        }
     ))
    }

    const handleNewPub = () => {
      props.setValues({
        category: "",
        content: "",
        description:"",
        admin:"N",
        subcategory: "",
        title:"",
        user: user?.id
      })
      props.handleOpen()
    }

      const handleClickSend = async () => {
        if(props.values.id > 0) {
          await handleEdit()  
        } else {
          await handlePost()
        }  
      }

      const handlePost = async () => {
        try {
          await axios.post(url, props.values).then( (res) => {
            ShowMessage('success', res.data.message);
            props.setValues({});
            props.handleClose();
            props.setChanged(!props.changed);
          }
        )
        } catch (err) {
        throw console.log(err);
        }
      }

      const handleEdit = async () => {
        try {
          await axios.put(url, props.values).then( (res) => {
            ShowMessage('success', res.data.message);
            props.setValues({});
            props.handleClose();
            props.setChanged(!props.changed);
          }
        )
        } catch (err) {
        throw console.log(err);
        }
      }

    const buttons = [
        "undo",  "redo", "|", "bold", "strikethrough",  "underline",
        "italic", "|", "superscript",  "subscript",  "|",  "align",
        "|", "ul", "ol", "outdent", "indent", "|", "font", "fontsize",
        "brush", "paragraph", "|", "image", "link", "table", "|",
        "hr", "eraser", "copyformat", "|", "fullsize", "selectall",
        "print", "|", "source",  "|",  
      ];

      const editorConfig = {
          readonly: false,
          toolbar: true,
          spellcheck: true,
          language: "en",
          toolbarButtonSize: "medium",
          toolbarAdaptive: false,
          showCharsCounter: true,
          showWordsCounter: true,
          showXPathInStatusbar: false,
          askBeforePasteHTML: false,
          askBeforePasteFromWord: false,
          theme: localStorage.getItem('theme'),
          buttons: buttons,
          width: "100%",
          height: 500
        };

  return (
    <div>        
        <Dialog open={props.open} disableEnforceFocus className='dialog-new-pub'     
            sx={{
            "& .MuiDialog-container": {
            "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "80%",  // Set your width here
                },
            },
            }} 
        >
            <DialogTitle>Inclusão de Publicação</DialogTitle>
            <Box sx={{m: 1}}>
                <TextField
                    className='label-titulo'
                    autoFocus
                    name="title"
                    value={props.values.title}
                    defaultValue=" "
                    label="Titulo da Publicação"
                    type="text"
                    onChange={(e) => handleChangeValues(e)}
                    fullWidth
                    variant="outlined"
                    sx={{marginBottom: 2}}
                />
                <TextField
                    className='label-descricao'
                    autoFocus
                    name="description"
                    value={props.values.description}
                    defaultValue=" "
                    label="Descrição da Publicação"
                    type="text"
                    onChange={(e) => handleChangeValues(e)}
                    fullWidth
                    multiline
                    variant="outlined"
                    sx={{marginBottom: 2}}
                />
                <Grid container columns={12} spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="combo-box-categories"
                    options={props.categories}
                    sx={{ mb: 2 }}
                    getOptionLabel={ (option) => {
                      if(!option){
                        return ''
                      }
                      if(typeof(option) === "string"){
                        return option;
                      } else if(typeof(option) === "object"){
                        return option['name'];
                      }     
                    }} 
                    getOptionKey={(option) => option['id'] || 0}   
                    value={props.values.category}
                    onChange={(event, values) => handleChangeCategory(values)}
                    renderInput={(params) => 
                    <TextField
                      name="category"
                      {...params} label="Categoria" 
                    />}
                  />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                  <Autocomplete
                    fullWidth
                    disablePortal
                    id="combo-box-subcategories"
                    options={props.subcategories.filter((s) => {
                      return s.category === props.values.id_category
                    })}
                    sx={{ mb: 2 }}
                    getOptionLabel={ (option) => {
                      if(!option){
                        return ''
                      }
                      if(typeof(option) === "string"){
                        return option;
                      } else if(typeof(option) === "object"){
                        return option['name'];
                      }     
                    }}   
                    onChange={(event, values) => handleChangeSubCategory(values)}
                    value={props.values.subcategory}
                    renderInput={(params) => 
                    <TextField
                      className='Subcategoria'
                      autoFocus
                      name="subcategory"
                      fullWidth
                      sx={{marginBottom: 2}}
                      // value={props.values.categoria}
                      {...params} label="Subcategoria" 
                    />}
                  />

                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                    <FormControl fullWidth>
                      <Typography>
                      <Checkbox
                        labelId="visibilidade-label"
                        label="Quem pode ver, todos?"
                        name="admin"

                        checked={checked}
                        onChange={(e) => handleChangeAdmin(e)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                        Somente Técnicos
                      </Typography>

                    </FormControl>  

                    </Grid>
                </Grid >
                <Box className="box-editor">
                <JoditEditor 
                    ref={editor}
                    value={props.values.content && props.values.content}
                    config={editorConfig}
                    onBlur={newContent => HandleContent(newContent)}
                    onChange={newContent => {}}
                />
                </Box>
            </Box>           
            <DialogActions sx={{paddingRight: 3}}>
                <Button onClick={() => props.handleClose()} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={() => handleClickSend()} variant="outlined" color="success">Salvar</Button>
            </DialogActions>
        </Dialog>

        <SpeedDial
            ariaLabel="SpeedDial openIcon example"
            sx={{ position: 'fixed', bottom: 16, right: 16, }}
            icon={<SpeedDialIcon  />}
            onClick={() => handleNewPub()}
        >
        </SpeedDial>
    </div>
  )
}

export default CriarPub