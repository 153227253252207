import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import InputMask from 'react-input-mask';
import ClientsActualizations from '../clientsActualizations';

let url = process.env.REACT_APP_API+ "/client/";
let disabled = true;
const Client = (props) => {
  const { editData, postData } = useFetchData(url)
  const { ShowMessage } = useShowMessages();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlerChangeValues = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      [value.target.name] : value.target.value,
    }));

  };

  const handleClose = () => {
    props.setOpen(false)
  }

  const handleClickSend = async () => {
    const res =  (!props.editing) ? await postData(url, props.values) : await editData(url, props.values);
    if(res.success){
      ShowMessage('success', res.msg);
      props.handleChanged();
      handleClose();
    }else{

      ShowMessage('error', res.msg);

    }
  }

  const handleChangeChecks = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      [value.target.name] : (value.target.checked) ? 'Y' : 'N',
    }));
  }
  const errPassword =( props.values.password != props.values.password_confirm); 

  return (
    <div>  
      <Dialog open={props.open} >
        <DialogTitle >Cadastro de Cliente</DialogTitle>
        <DialogContent sx={{minHeight: '70vh'}}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="Cadastro de Clientes"
            variant="fullWidth"
            sx={{mb: 3}}
          >
            <Tab label="Dados do Cliente"/>
            <Tab label="Atualizações" disabled={false}/>
          </Tabs>
          <Grid container spacing={1} columns={ 12 } sx={{float: "center", display: (value === 0) ? '' : 'none'}} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="nome_fantasia"
                value={props.values.nome_fantasia}
                defaultValue=" "
                label="Nome Fantasia"
                type="text"
                onChange={handlerChangeValues}
                fullWidth
                disabled={disabled}
              />                              
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="razao_social"
                value={props.values.razao_social}
                defaultValue=" "
                label="Razão Social"
                type="text"
                onChange={handlerChangeValues}
                fullWidth
                disabled={disabled}
              />                              
            </Grid>
            <Grid item xs={12} md={4} marginBottom={1} marginTop={1}>
              <TextField
                fullWidth
                label="Código Interno"
                type="number"
                name='id_gerente'
                disabled={disabled}
                value={props.values.id_gerente}
                onChange={handlerChangeValues}
              /> 
            </Grid>  
            <Grid item xs={12} sm={4} md={4} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="cpf_cnpj"
                value={props.values.cpf_cnpj}
                defaultValue=" "
                label="CPF / CNPJ"
                type="text"
                onChange={handlerChangeValues}
                fullWidth
                disabled={disabled}
              />                              
            </Grid>
            <Grid item xs={12} sm={12} md={4}  marginBottom={1} marginTop={1}>
              <InputMask
                  mask="(99) 99999-9999"
                  maskChar=""
                  onChange={handlerChangeValues}
                  value={props.values?.phone_number}
                  fullWidth
                  disabled={disabled}
              >
                  {() =>                     
                  <TextField 
                    label="Número para contato" 
                    variant="outlined"  
                    name='phone_number'
                    fullWidth
                  >
                  </TextField> }
              </InputMask> 
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="address_street"
                value={props.values.address_street}
                defaultValue=" "
                label="Endereço"
                type="text"
                onChange={handlerChangeValues}
                fullWidth
                disabled={disabled}                
              />                              
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="address_number"
                value={props.values.address_number}
                defaultValue=" "
                label="Número"
                type="text"
                onChange={handlerChangeValues}
                fullWidth
                disabled={disabled}                
              />                              
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="address_neighborhood"
                value={props.values.address_neighborhood}
                defaultValue=" "
                label="Bairro"
                type="text"
                onChange={handlerChangeValues}
                fullWidth
                disabled={disabled}                
              />                              
            </Grid>  
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="address_CEP"
                value={props.values.address_CEP}
                defaultValue=" "
                label="CEP"
                type="text"
                onChange={handlerChangeValues}
                fullWidth
                disabled={disabled}                
              />                              
            </Grid> 
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="address_city"
                value={props.values.address_city}
                defaultValue=" "
                label="Cidade"
                type="text"
                onChange={handlerChangeValues}
                fullWidth
                disabled={disabled}                
              />                              
            </Grid> 
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="address_state"
                value={props.values.address_state}
                defaultValue=" "
                label="Estado"
                type="text"
                onChange={handlerChangeValues}
                fullWidth
                disabled={disabled}                
              />                              
            </Grid> 
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="address_complement"
                value={props.values.address_complement}
                defaultValue=" "
                label="Complemento"
                type="text"
                onChange={handlerChangeValues}
                fullWidth
                disabled={disabled}                
              />                              
            </Grid>  
            <Grid item xs={12} md={4}  marginBottom={1} marginTop={1}>
              <FormControl fullWidth >
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  label="Status"
                  name="status"
                  value={props.values.status}
                  onChange={handlerChangeValues}
                  inputProps={{ readOnly: disabled }}
                >
                  <MenuItem value={'1 - ATIVO'}><em>1 - Ativo</em></MenuItem>
                  <MenuItem value={'2 - CANCELADO'}><em>2 - Cancelado</em></MenuItem>
                  <MenuItem value={'3 - SUSPENSO'}><em>3 - Suspenso</em></MenuItem>
                  <MenuItem value={'4 - MAQUINAS'}><em>4 - Máquinas</em></MenuItem>
                  <MenuItem value={'7 - SIRRUS'}><em>7 - Sirrus</em></MenuItem>
                </Select>
              </FormControl>
            </Grid>    
            <Grid item xs={12} md={4} marginBottom={1} marginTop={1}>
              <FormControl fullWidth>
                <InputLabel id="tipo-prioridade-label">Classificação</InputLabel>
                  <Select
                      labelId="tipo-prioridade-label"
                      label="Classificação"
                      fullWidth
                      name="priority"
                      value={props?.values?.priority}   
                      onChange={handlerChangeValues}
                      inputProps={{ readOnly: disabled }}
                  >
                      <MenuItem value={1}><em>Lista Padrão</em></MenuItem>
                      <MenuItem value={2}><em>Lista Vip</em></MenuItem>
                  </Select>
              </FormControl> 
            </Grid>   
            <Grid item xs={12} md={4} marginBottom={1} marginTop={1}>
              <FormControl fullWidth>
                <InputLabel id="tipo-atendimento-label">Tipo</InputLabel>
                <Select
                    labelId="tipo-atendimento-label"
                    label="Tipo"
                    fullWidth
                    name="type_client"
                    value={props.values?.type_client} 
                    onChange={(e) => handlerChangeValues(e)}
                    inputProps={{ readOnly: disabled }}
                >
                    <MenuItem value={0}><em>Automação</em></MenuItem>
                    <MenuItem value={1}><em>Ponto</em></MenuItem>
                    <MenuItem value={2}><em>Assistência</em></MenuItem>
                    <MenuItem value={3}><em>Maker</em></MenuItem>
                    <MenuItem value={4}><em>Site</em></MenuItem>
                </Select>
                </FormControl>  
            </Grid>                                                                      
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
              <TextField
                autoFocus
                name="alert_msg"
                value={props.values.alert_msg}
                label="Mensagem de Alerta"
                type="text"
                onChange={handlerChangeValues}
                multiline
                fullWidth
                disabled={disabled}                
              /> 
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox 
                  name={'show_alert'}
                    checked={props.values.show_alert === 'Y'} 
                    onChange={handleChangeChecks}
                    inputProps={{ 'aria-label': 'controlled' }} 
                  />
                }
                label='Usar Mensagem de Alerta'
              />    
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox 
                    name={'hour_technical'}
                    checked={props.values.hour_technical === 'Y'} 
                    onChange={handleChangeChecks}
                    inputProps={{ 'aria-label': 'controlled' }} 
                    
                  />
                }
                label='Cliente hora técnica'
              />                           
            </Grid>                             
          </Grid>
          <ClientsActualizations tab={value} client={props.values}/>
        </DialogContent>
        <DialogActions sx={{paddingRight: 4}}>
          <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
          <Button onClick={() => handleClickSend()} variant="outlined" color="success">Salvar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Client;