import React, {  useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {  Autocomplete, Box, Checkbox, Chip, Collapse, Divider,  FormControl,  FormControlLabel,  Grid,  IconButton,  InputAdornment,  InputLabel,  MenuItem,  OutlinedInput,  Select,  Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputMask from 'react-input-mask';
import { Fab } from '@mui/material';
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import useClientSelection from '../../../../hooks/useClientSelection/useClientSelection';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';
import { useCryptoData } from '../../../../hooks/useCryptoData/useCryptoData';
import { useDebounce } from '../../../../hooks/useDebounce/useDebounce';

moment.tz.setDefault('America/Manaus');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Manaus');

const IsDev = process.env.REACT_APP_AMBIENTE_DEV === 'true';
const url = process.env.REACT_APP_API+ "/requests";
const urlFR = process.env.REACT_APP_API+ '/fr';
let urlTypeServices = process.env.REACT_APP_API+ "/requests/typeServices";
const rangeTimes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];


export default function RequestClient(props) {
  const { decryptPermissionsTree } = useCryptoData();
  const user = decryptPermissionsTree(sessionStorage.getItem('data'));
  const [filter, setFilter] = useState('');
  const { data: listRequest,  postData} = useFetchData(urlFR, {} )
  const [expandRequests, setExpandRequests] = useState(false);
  const { ShowMessage } = useShowMessages();
  const { selectedClient, openModal, modal, setSelectedClient } = useClientSelection(() => {});
  const { data : TypeServices} = useFetchData(urlTypeServices);
  const [errors, setErrors] = useState({});
  const [clientPriority, setClientPriority] = useState(false);
  const { debounce } = useDebounce();
  
  debounce((1*1000), () => {
    setClientPriority(!clientPriority);
  });
  
  const handleChangeErrors = (err, state) => {
    setErrors((prevErrors) => ({
        ...prevErrors,
        [err]: state
      }));
  };


  function onlyNumbers(str) {

    var numbers = str.replace(/[^0-9]/g, '');
    
    return numbers;
  }


  const handleTitleClick = (value) => {
    props.setValues(prevState => {
      return { ...prevState, title: value?.description}
     });
    props.setValues(prevState => {
      return { ...prevState, id_reason: value?.id}
    });   
     setFilter(value?.description); 
     handleChangeErrors('id_reason', false);
     setExpandRequests(false);     
  }; 

  const handleChangeValues = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));
   
  }; 

  const handleChangeChecks = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      [value.target.name] : (value.target.checked) ? 'Y' : 'N',
    }));
  }

  const handleCancel = () => {
    props.setValues({});
    setSelectedClient({});
    handleClose();
    if(props.admin){
      setSelectedClient({});
    }

  }
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleFilterRequests = (e) => {
    handleChangeErrors('id_reason', true);
    props.setValues(prevState => {
      return { ...prevState, id_reason: null}
    });   
    setFilter(e.target.value);
  }

  const selectClient = () => {
    openModal();
  }

  const changeTimeToStart = (value) => {
    handlerChangeStartDate(value)
  }


  const handleClickSend = async () => {

    if(props.admin && !props.values?.id_reason){
      handleChangeErrors('id_reason', true);
      return false;
    } 


    if(!selectedClient?.id){
      ShowMessage('error', 'Informe corretamente o cliente.');
      return;
    }
    if(!props.values?.description || props.values?.description.length < 5){
      ShowMessage('error', 'Informe a descrição da solicitação.');
      return;
    }

    if(!props.admin &&  !props.values?.phone_number || !props.admin && onlyNumbers(props.values?.phone_number).length < 10){
      ShowMessage('error', 'Informe corretamente o número para contato.');
      return;
    }


    let request = props.values;
    request.client = selectedClient?.id;
    request.priority = selectedClient.priority;
    request.cpf_cnpj = selectedClient?.cpf_cnpj;
    request.status = (request?.user) ? 2 : 1;
    request.requestReleased = selectedClient?.requestReleased;
    request.start_date = dayjs(request?.start_date).utc(true);
    request.type_request = selectedClient?.type_client;

    await postData(url, request).then((res) => {
      if(res.success){
        ShowMessage('success', 
          'Atendimento Aberto com Sucesso', 
          `Em breve nossa equipe entrará em contato. 
          Acompanhe o andamento pela tela de atendimentos.`);
        if(props.requestsPage) props.handleChanged();
        if(props.admin){
          setSelectedClient({});
        }
        props.setValues({})
        handleClose();
        
      }else{
          ShowMessage('warning', 'Ops, algo deu errado',
          "Por favor, nos informe se o problema persistir");
    }
  })
  }



  const selectTime = (time) => {
    const now = dayjs.tz().format();
    const start_date = moment(now).add(time, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    changeTimeToStart(dayjs.tz(start_date));
  }

  const handleChangeTypeService = (values) => {

    props.setValues((prevValues) => (
      {
          ...prevValues, "type_service": (!values) ? null : values.id,
    }));  
    props.setValues((prevValues) => (
      {
          ...prevValues, "type_service_description": (!values) ? '' : values.description,
    }));   
  }

  const handlerChangeStartDate= (value) => {  
    props.setValues((prevValues) => ({
      ...prevValues, 
      'start_date' : dayjs(value)
    }));
  };

  return (
    <div>
      {modal}
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Abertura de Atendimento</DialogTitle>
        <DialogContent>
          <Box sx={{textAlign: 'center', mb: 1, color: '#f44336', 
                        display: (props.admin && selectedClient?.priority === 2) ? 'flex' : 'none', 
                        flexDirection: 'row',
                        justifyContent: 'center', minHeight: 38.5,
                        border: '2px solid red'}}>
            <Typography
              sx={{
                fontSize: 23,
                display: (clientPriority) ? '' : 'none'
              }}
            >
              Cliente Preferencial!
            </Typography>
          </Box>    
          <DialogContentText sx={{display: (props.admin) ? 'none' : '' }} >
            Procure descrever sua solicitação de forma clara, nossa equipe entrará em contato assim que recebermos sua solicitação
          </DialogContentText>
          <Box sx={{ '& .MuiTextField-root': { mt: 2, }, }}>
            <FormControl sx={{ width: '100%', mt: 2, display: (props.admin) ? '' : 'none' }} variant="outlined">
              <InputLabel >Cliente</InputLabel>
              <OutlinedInput
                defaultValue={' '}
                label='Cliente'
                type={'text'}
                value={selectedClient?.razao_social}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton   
                      onClick={selectClient}
                      edge="end"
                    >
                      <SearchIcon/>
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box                   
              onFocus={() => setExpandRequests(true)}
              onBlur={() => setExpandRequests(false)}
              sx={{display: (props.admin) ? '' : 'none'}}
            >
              <TextField
                  InputLabelProps={{ shrink: true }}
                  autoFocus
                  name="title"
                  value={filter}
                  label="Tipo de Atendimento"
                  placeholder='Motivo da Solicitação"'  
                  type="text"
                  onChange={handleFilterRequests}
                  fullWidth
                  variant="outlined"
                  error={errors?.id_reason}
                  helperText={errors?.id_reason && "Informe o motivo da solicitação"}                  
              />
              <Collapse in={expandRequests} timeout="auto" unmountOnExit sx={{ textAlign: "left", }}>
                <Box sx={{float: "left",  '& .MuiChip-root': { m: 1}, marginBottom: 2}}>
                      {listRequest?.filter((i) => i?.description.toLowerCase().includes(filter.toLowerCase())).slice(0, 9).map((item) =>(
                          <Chip 
                          key={item.id}
                            label={item?.description} 
                            variant="outlined" 
                            onClick={(e) => {handleTitleClick(item)}} 
                          />   
                      ))}
                </Box>
              </Collapse>  
            </Box>
                <TextField
                  required
                    label="Descrição da solicitação"
                    multiline
                    fullWidth  
                    name='description'
                    defaultValue={props.values?.description}
                    onBlur={handleChangeValues}
                    placeholder='Descreve sua solicitação detalhadamente'           
                />
                <Divider sx={{m: 1}}/>
                <Typography fontSize={15}  >Informações para contato</Typography>
                <Grid container spacing={1} columns={ 2 } sx={{float: "center",}} >
                  <Grid item xs={2} sm={2} md={1} lg={1} xl={1} >
                    <TextField 
                          id="filled-basic"  
                          label="Falar com:" 
                          variant="outlined" 
                          name='contact'
                          defaultValue={props.values?.contato}
                          onBlur={handleChangeValues}
                          fullWidth

                      />
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} lg={1} xl={1} >
                  <InputMask
                      mask="(99) 99999-9999"
                      disabled={false}
                      maskChar=""
                      onBlur={handleChangeValues}
                      defaultValue={props.values?.phone_number}
                      fullWidth
                    >
                      {() =>                     
                      <TextField 
                        id="filled-basic"  
                        label="Número para contato" 
                        variant="outlined"  
                        name='phone_number'
                        fullWidth
                        error={!props.admin &&  errors?.phone_number}
                        helperText={!props.admin &&  errors?.phone_number && "Informe corretamente o número para contato"} 
                      >
                      </TextField> }
                    </InputMask> 
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{ display: (props.admin) ? '' : 'none' }}>
                    <Autocomplete
                          disablePortal
                          fullWidth
                          options={TypeServices}
                          getOptionLabel={ (option) => option['description'] || option }    
                          value={props.values?.type_service_description}
                          onChange={(event, values) => handleChangeTypeService(values)}
                          renderInput={(params) => 
                          <TextField
                            name="type_service"
                            {...params} label="Tipo de Serviço" 
                          />}
                     />
                  </Grid>
                  {/* <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{mt: 2, display: (props.admin) ? '' : 'none' }}>
                    <FormControl fullWidth >
                        <InputLabel id="tipo-atendimento-label">Tipo</InputLabel>
                        <Select
                            labelId="tipo-atendimento-label"
                            label="Tipo"
                            fullWidth
                            name="type_request"
                            value={props.values?.type_request} 
                            onChange={(e) => handleChangeValues(e)} 
                        >
                            <MenuItem value={0}><em>Automação</em></MenuItem>
                            <MenuItem value={1}><em>Ponto</em></MenuItem>
                            <MenuItem value={2}><em>Assistência</em></MenuItem>
                        </Select>
                      </FormControl>  
                  </Grid> */}
                  <Grid item xs={12} sx={{textAlign: 'left', display: (props.admin) ? '' : 'none'}}>
                      <FormControlLabel
                        control={
                          <Checkbox 
                            name={'hide_panel'}
                            checked={props.values?.hide_panel === 'Y'} 
                            onChange={handleChangeChecks}
                            inputProps={{ 'aria-label': 'controlled' }} 
                            
                          />
                        }
                        label='Ocultar do Painel'
                      /> 
                    </Grid> 
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2} marginBottom={1} sx={{display: (props.admin) ? '' : 'none'}}>
                  <LocalizationProvider fullWidth dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <MobileDateTimePicker 
                      fullWidth
                      sx={{width: '100%', mt: 2}}
                      label={'Previsão para Atendimento'} 
                      ampm={false} 
                      openTo="hours" 
                      name="start_date"
                      value={dayjs.tz(props.values?.start_date)}
                      format="DD/MM/YYYY HH:mm"   
                      onChange={handlerChangeStartDate}
                    />
                  </LocalizationProvider>   
                  <Box sx={{float: "left",  '& .MuiChip-root': { m: 1}, display: (props.admin) ? '' : 'none' }}>
                    {rangeTimes.map((item) =>(
                      <Chip 
                        key={item}
                        label={item} 
                        variant="outlined" 
                        onClick={(e) => {selectTime(item)}} 
                      />   
                    ))}  
                  </Box>                 
                  </Grid>                  
                </Grid>
            </Box>  
        </DialogContent>
        <DialogActions>
          <Button  variant='outlined' color='warning' onClick={handleCancel}>Cancelar</Button>
          <Button  variant='outlined' color='success' onClick={handleClickSend}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}