import { Box, Typography } from '@mui/material';
import React, { useState } from 'react'
import RequestsTable from '../../../../components/requests/requestsTable/requestsTable';
import RequestAdmin from '../../../../components/requests/requestsModais/requestAdmin/requestAdmin';

const RequestsPage = () => {
  const [open, setOpen] = useState();
  const [values, setValues] = useState({});
  const [editing, setEditing] = useState(false);
  const [changed, setChanged ] = useState(false); 

  const handleChanged = () => {
    setChanged(!changed);
  }

  return (
    <div style={{padding: 20,  overflow: 'scroll', width: '100%', height: '100%'}}>
      <Box className="header-clients">
        <Typography variant="h4"  color="text.secondary" >
          Listagem de Atendimentos
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Consulte todos os atendimentos.   
        </Typography>
      </Box>
      <Box>
      <RequestAdmin values={values} open={open} setValues={setValues} setOpenRequest={setOpen} changed={setChanged} setChanged={setChanged} handleChanged ={handleChanged}  />
      </Box>
      <RequestsTable
        open={open}
        setOpen={setOpen} 
        values={values}
        setValues={setValues}
        editing={editing}
        setEditing={setEditing}
        changed={changed}
        handleChanged={handleChanged}
      />

    </div>
  )
}

export default RequestsPage;