import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputBase, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FilterListIcon from '@mui/icons-material/FilterList';

//hooks 
import { useFetchData } from '../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../hooks/useMessages/useMessages';

let url = process.env.REACT_APP_API+ "/client/all";


export default function TableUsersActualizations(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState({actualization_is_active: 'T', type_bkp: 2, days_last_bkp: 0});
  const { ShowMessage, msgConfirm } = useShowMessages();
  const { data : listClients} = useFetchData(url, {filter: filter, 
                                                  page: page, rowsPerPage: rowsPerPage, 
                                                  typeAPIConfig: filters.actualization_is_active, 
                                                  type_bkp: filters.type_bkp, days_last_bkp: filters.days_last_bkp}, props.changed, true);
  const [openFilter, setOpenFilter] = useState(false);
 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.handleChanged();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    props.handleChanged();
  };

  const handleEditUser = (values) =>{
    props.setValues(values);
    props.setEditing(true);
    props.setOpen(true);
  }

  
  const daysWithoutBackup = (lastBackupDate) => {
    const currentDate = new Date();
    const dateMinus5Days = new Date(currentDate.setDate(currentDate.getDate() - 5));
  
    return lastBackupDate < dateMinus5Days;
  };

  const getAlertCliente = async (client) => {
    if (client?.priority === 2) {
      await ShowMessage('warning', 'Cliente Preferencial', 'Esse cliente deve ser atendido com preferência!', 10);
    }

    
    if (client?.data_last_bkp !== null) {
      const lastBackupDate = new Date(client.data_last_bkp);
      const currentDate = new Date();
      
      if (daysWithoutBackup(lastBackupDate)) {
        const diffTime = Math.abs(currentDate - lastBackupDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        await ShowMessage('warning', 'Aviso!', `O último backup em nuvem do cliente foi há ${diffDays} dias!`, 10);
      }
    } else {
      await ShowMessage('warning', 'Configuração de Backup', 'Esse cliente ainda não possui o novo gerenciador de backup configurado!', 10);
    }
  }
  
  const handleSetFilter = (filter) => {
    setFilter(filter.target.value);
  }

  const handleSearch = () => {
    props.handleChanged();
  }

  const getStatus = (status) => {
    switch(status){
      case 'N': return 'Não'; 
      case 'Y': return 'Sim';
    } 
  } 

  const countFilter = ()=>{

    let count = 0;

    if(filters.id_sistema){
      count = count + 1;
    }
 
    setFilters((prevValues) => (
      {...prevValues, 
        'count' : count}
    )); 
  }

  const handleCloseFilter = () => {
    setOpenFilter(false);
  }

  const handleOpenFilter = () => {
    setOpenFilter(true);
  }

  const applyFilter = () => {
    props.handleChanged();
    handleCloseFilter();
    countFilter();
  }

  const handlerChangeFilters = (value) => {
    setFilters((prevValues) => ({
      ...prevValues, 
      [value.target.name] : value.target.value,
    }));

  };

  return (
    <Box sx={{ width: '100%', height: '100%'}}>
      <Paper elevation={3} sx={{ p: '2px 4px', display: 'flex',  mb: 2, border: '1px solid rgba(0, 0, 0, 0)', ':hover': { border: '1px solid' } }}>
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Busque por Nome Fantasia, Razão Social e CNPJ/CPF"
            value={filter}
            onChange={handleSetFilter}
            onKeyDown={(e) => {if(e.key === "Enter")handleSearch()}}
        />
        <IconButton type="button" sx={{ p: '10px' }} title='Filtros' onClick={handleOpenFilter}>
          <Badge color="primary" variant="standard" badgeContent={filters?.count}>
            <FilterListIcon />
          </Badge>        
        </IconButton> 
        <IconButton type="button" sx={{ p: '10px' }} title='Buscar' onClick={handleSearch}>
            <SearchIcon />
        </IconButton>
      </Paper >
      <Paper >
        <TableContainer sx={{ overflow: 'scroll', height: '70vh'}} >
          <Table stickyHeader  sx={{ width: '100%' }} >
            <TableHead>
              <TableRow>
                  <TableCell>Nome Fantasia</TableCell>
                  <TableCell>Usuário</TableCell>
                  <TableCell>Senha</TableCell>    
                  <TableCell>API Configurada</TableCell>     
                  <TableCell>Detalhes</TableCell>                                              
              </TableRow>
            </TableHead>
            <TableBody>
            {listClients && listClients.map((row) => (
              <TableRow key={row.id} sx={{height: '25px'}} onClick={() => getAlertCliente(row)}>
                <TableCell component="th" scope="row">
                  {row.nome_fantasia}
                </TableCell>
                <TableCell align="left">{row.contract}</TableCell>
                <TableCell align="left">{row.cpf_cnpj}</TableCell>
                <TableCell align="left">{getStatus(row.actualization_is_active)}</TableCell>
                <TableCell align="left">
                  <Button onClick={() => handleEditUser(row)}>
                      <VisibilityIcon />
                  </Button></TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage='Registros por página'
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={(listClients && listClients) ? Math.ceil(listClients[0]?.count) : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog open={openFilter} onClose={handleCloseFilter}> 
        <DialogTitle>Opções de Filtro</DialogTitle>
        <DialogContent sx={{minHeight: 300, minWidth: 400}}>
          <FormControl fullWidth sx={{mt: 2}}>
            <InputLabel id="api-label">API Configurada</InputLabel>
            <Select
                labelId="api-label"
                label="API Configurada"
                name="actualization_is_active"
                value={filters?.actualization_is_active}
                inputProps={{ readOnly: false }}
                onChange={handlerChangeFilters}
            >
                <MenuItem value={'T'}><em>Todos</em></MenuItem>
                <MenuItem value={'N'}><em>Não</em></MenuItem>
                <MenuItem value={'Y'}><em>Sim</em></MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{mt: 2}}>
            <InputLabel id="bkp-label">Modo de Configuração Backup</InputLabel>
            <Select
                labelId="bkp-label"
                label="Modo de Configuração Backup"
                name="type_bkp"
                value={filters?.type_bkp}
                inputProps={{ readOnly: false }}
                onChange={handlerChangeFilters}
            >
                <MenuItem value={2}><em>Todos</em></MenuItem>
                <MenuItem value={0}><em>API Backup</em></MenuItem>
                <MenuItem value={1}><em>Dropbox</em></MenuItem>
                <MenuItem value={3}><em>Não Informado</em></MenuItem>
            </Select>
          </FormControl>
          <TextField
                fullWidth
                label="Último backup válido foi há mais de:"
                type="number"
                name='days_last_bkp'
                variant="filled"
                value={filters?.days_last_bkp}
                onChange={handlerChangeFilters}
                sx={{mt: 2}}
          /> 
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={applyFilter}>Aplicar</Button>
        </DialogActions>        
      </Dialog>
    </Box>
  );
}