import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {useFetchData} from '../../../../hooks/useFetchData/useFetchData';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, IconButton, InputBase, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

let url = process.env.REACT_APP_API+ "/helpdesk/categories";

  function TableCategories(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = useState('');

  const { data : categories } = useFetchData(url, {filter: filter, page: page, rowsPerPage: rowsPerPage}, props.changed, true);

  const handleSetFilter = (filter) => {
    setFilter(filter.target.value);
  }

  const handleSearch = () => {
    props.handleChanged();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditCategory = (values) =>{
    props.setValues(values);
    props.setEditing(true);
    
    props.setOpen(true);
  }
 

  return (
    <Box sx={{ width: '100%', height: '100%'}}>
      <Paper elevation={3} sx={{ p: '2px 4px', display: 'flex',  mb: 2, border: '1px solid rgba(0, 0, 0, 0)', ':hover': { border: '1px solid' } }}>
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Pesquisar"
            value={filter}
            onChange={handleSetFilter}
            onKeyDown={(e) => {if(e.key === "Enter")handleSearch()}}
        />
        <IconButton type="button" sx={{ p: '10px' }} title='Buscar' onClick={handleSearch}>
            <SearchIcon />
        </IconButton>
      </Paper >      
      <Paper sx={{ width: '100%',  height: '100%' }}>

        <TableContainer sx={{ overflow: 'scroll', height: '70vh'}}>
          <Table stickyHeader  sx={{ width: '100%' }} >
            <TableHead>
              <TableRow>
                <TableCell >Categorias</TableCell>
                <TableCell align="right" >Editar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {categories && categories.map((row) => (
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>

                <TableCell align="right">
                  <Button onClick={()=> handleEditCategory(row)}>
                      <EditOutlinedIcon />
                  </Button>
                </TableCell>

              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage='Registros por página'
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={(categories && categories) ? Math.ceil(categories[0]?.count/rowsPerPage) : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export { TableCategories}