import { Box, IconButton, ImageListItem, ImageListItemBar, Modal } from '@mui/material'
import React from 'react'

const ViewImage = (props) => {

  return (
    <Modal className='dialog-video' open={props.open}
    
    keepMounted
        fullWidth    
        onClose={() => props.setOpen(false)}    
    >
      <Box className='box-video' 
        sx={{
          width:{xs: "90%", sm: "90%", md: "80%", lg: "70%", xl: "50%"},
          height:{xs: "30%", sm: "60%", md: "60%", lg: "60%", xl: "56%"}
        }}
    >
        <ImageListItem key={props.image.id} sx={{width: "100%", height: '100%'}}>
            <img 

                src={props.image.path}
                alt={props.image.title}
                loading="lazy"
            />
            <ImageListItemBar
                title={props.image.title}
                subtitle={props.image.author}
            />
        </ImageListItem>
      </Box>
    </Modal>
  )
}

export default ViewImage