import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

//hooks 
import {useFetchData} from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';

let url = process.env.REACT_APP_API+ "/client/all";


export default function TableClients(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState('');
  const { ShowMessage, msgConfirm } = useShowMessages();
  const { data : listClients} = useFetchData(url, {filter: filter, page: page, rowsPerPage: rowsPerPage, listMaquinas: true}, props.changed, true);
   
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.handleChanged();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    props.handleChanged();
  };

  const handleEditUser = (values) =>{
    props.setValues(values);
    props.setEditing(true);
    props.setOpen(true);
  }

  const getAlertCliente = async (client) => {
    if (client?.priority === 2) {
      await ShowMessage('warning', 'Cliente Preferencial', 'Esse cliente deve ser atendido com preferência!', 10);
    }
  }
  
  const handleSetFilter = (filter) => {
    setFilter(filter.target.value);
  }

  const handleSearch = () => {
    props.handleChanged();
  }

  const getStatus = (status) => {
    switch(status){
      case '1 - ATIVO': return '1 - Ativo'; 
      case '2 - CANCELADO': return '2 - Cancelado';
      case '3 - SUSPENSO': return '3 - Suspenso';
      case '4 - MAQUINAS': return '4 - Máquinas';
      case '7 - SIRRUS': return '7 - Sirrus';
    } 
  } 

  return (
    <Box sx={{ width: '100%', height: '100%'}}>
      <Paper elevation={3} sx={{ p: '2px 4px', display: 'flex',  mb: 2, border: '1px solid rgba(0, 0, 0, 0)', ':hover': { border: '1px solid' } }}>
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Busque por Nome Fantasia, Razão Social e CNPJ/CPF"
            value={filter}
            onChange={handleSetFilter}
            onKeyDown={(e) => {if(e.key === "Enter")handleSearch()}}
        />
        <IconButton type="button" sx={{ p: '10px' }} title='Buscar' onClick={handleSearch}>
            <SearchIcon />
        </IconButton>
      </Paper >
      <Paper >
        <TableContainer sx={{ overflow: 'scroll', height: '70vh'}} >
          <Table stickyHeader  sx={{ width: '100%' }} >
            <TableHead>
              <TableRow>
                  <TableCell>Nome Fantasia</TableCell>
                  <TableCell>Razão Social</TableCell>
                  <TableCell>Código Gerente</TableCell>    
                  <TableCell>Status</TableCell>     
                  <TableCell>Editar</TableCell>                                              
              </TableRow>
            </TableHead>
            <TableBody>
            {listClients && listClients.map((row) => (
              <TableRow key={row.id} sx={{height: '25px'}} onClick={() => getAlertCliente(row)}>
                <TableCell component="th" scope="row">
                  {row.nome_fantasia}
                </TableCell>
                <TableCell align="left">{row.razao_social}</TableCell>
                <TableCell align="left">{row.id_gerente}</TableCell>
                <TableCell align="left">{getStatus(row.status)}</TableCell>
                <TableCell align="left">
                  <Button onClick={() => handleEditUser(row)}>
                      <EditOutlinedIcon />
                  </Button></TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage='Registros por página'
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={(listClients && listClients) ? Math.ceil(listClients[0]?.count) : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}