import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography, containerClasses } from '@mui/material';
import React, { useState } from 'react'
import TableFiles from '../../../../components/files/tableFiles';
import PostAddIcon from '@mui/icons-material/PostAdd';
import FileUpload from '../../../../components/files/fileUpload';
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { Description } from '@mui/icons-material';

let urlCategories = process.env.REACT_APP_API+ "/files/getAllCategories";

const FilesPage = () => {
  const [open, setOpen] = useState();
  const [values, setValues] = useState({});
  const [editing, setEditing] = useState(false);
  const [changed, setChanged ] = useState(false); 
  const [file, setFile] = useState({});
  const { data : categories } = useFetchData(urlCategories);

  const handleChanged = () => {
    setChanged(!changed);
  }

  const handleNewFile = () => {
    setValues({
          title: '',
          description: '',
          file: '',
        });
    setFile({});
    setEditing(false);
    setOpen(true);
  }

  const actions = [
    { icon: <PostAddIcon />, name: 'Novo Arquivo', action: handleNewFile },
  ];

  return (
    <div style={{padding: 20, overflow: 'scroll', width: '100%', height: '100%'}}>
      <Box>
      <FileUpload 
        values={values} 
        setValues={setValues}
        editing={editing} 
        setEditing={setEditing} 
        open={open} 
        setOpen={setOpen} 
        setChanged={setChanged} 
        handleChanged={handleChanged}
        setFile={setFile}
        file={file}
        categories={categories}
      />
        {/* <FileUpload 
          open={open}
          setOpen={setOpen} 
          values={values}
          setValues={setValues}
          editing={editing}
          setEditing={setEditing}
          handleChanged={handleChanged}
        /> */}
      </Box>
      <TableFiles
        open={open}
        setOpen={setOpen} 
        values={values}
        setValues={setValues}
        file={file}
        setFile={setFile}
        editing={editing}
        setEditing={setEditing}
        changed={changed}
        handleChanged={handleChanged}
        categories={categories}
      />

      <Box sx={{  transform: 'translateZ(0px)', flexGrow: 1 }}>
        <SpeedDial
          ariaLabel="SpeedDial Actions"
          sx={{ position: 'fixed', bottom: 140, right: 8}}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
            />
          ))}
        </SpeedDial>
      </Box>
    </div>
  )
}

export default FilesPage;