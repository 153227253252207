import { Button, Dialog, DialogActions, DialogTitle, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';


let urlSubCategories = process.env.REACT_APP_API+ "/helpdesk//subcategories";

const SubCategories = (props) => {
    
    const { ShowMessage } = useShowMessages();
    const {  editData, postData } = useFetchData(urlSubCategories);

    const handleChangeValues = (value) => {
  
        props.setValues((prevValues) => ({
          ...prevValues, 
          [value.target.name] : value.target.value,
        }));
    
      };
      const handleClose = () => {
        props.setOpen(false)
      }
    
      const handleClickSend = async () => {
        const values = [props.data.SubCategories]

        const res =  (!props.editing) ? await postData(urlSubCategories, props.values) : await editData(urlSubCategories, props.values);


        if(res.success){

          values[0].push({id: res.id,  name: props.values.name })
          props.editData((prevValues) => ({
            ...prevValues, 
            ['SubCategories'] : values[0],
          }));

          ShowMessage('success', res.msg);
          handleClose();
        }else{
    
          ShowMessage('error', res.msg);
    
        }
      }

  return (
    <div style={{width: '300px', background: 'red'}}>
        <Dialog open={props.open}  >
            <DialogTitle>Cadastro de SubCategorias</DialogTitle>
            <Box sx={{m: 1}}>
            <TextField
                    className='label-descricao'
                    autoFocus
                    name="name"
                    value={props.values.name}
                    label="Descriçao"
                    type="text"
                    onChange={handleChangeValues}
                    fullWidth
                    variant="outlined"
                    sx={{marginBottom: 2}}
                />
            </Box>           
            <DialogActions sx={{paddingRight: 3}}>
                <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={() => handleClickSend()} variant="outlined" color="success">Salvar</Button>
            </DialogActions>
        </Dialog>
        
    </div>
  )
}

export default SubCategories