import { Box} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'


const PageVisualizarManual = () => {

    const [searchParams, setSearchParams] = useSearchParams([]);

    const video = useMemo(() => {
        return  searchParams.get('video');
    }, [searchParams]);

    const playlist = useMemo(() => {
      return  searchParams.get('playlist');
  }, [searchParams]);
 
 

  return (
    <Box className='box-page-visualizar-video'
    
     sx={{width: '100%', height: '99.6vh'}}   

    >
              {
          (video != '') ? 
          <> 
          <iframe width= "100%" height="100%"  src={`https://www.youtube-nocookie.com/embed/${video}
                                                ?version=3&loop=1&playlist=${video}&modestbranding=1&rel=0&autoplay=1&vq=hd720` } 

            title="Sirrus Sistemas"
            frameBorder="0" 
            allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop" 
            allowFullScreen="0"
            autoplay="1"
            color={"white"}
            // showinfo="0"
            
          >     
          </iframe>
          </>
         : <CircularProgress/>
        }
    </Box>
  )
}

export default PageVisualizarManual