import { Box, Grid, Typography,  Stack, Pagination, Divider, Breadcrumbs } from '@mui/material'
import React, { useMemo, useState } from 'react'
import AppBarPrincipal from '../../../appBar/Appbar'
import CardPub from '../publicationCard/PublicationCard'
import './PublicationsPage.css'
import CriarPub from '../publication/Publication';
import { useSearchParams, Link } from 'react-router-dom';
import PublicationViwenPage from '../publicationViewPage/PublicationViewPage';
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import SearchBar from '../../../searchBar/SearchBar'

let url = process.env.REACT_APP_API+'/helpdesk/publications';
let urlCategories = process.env.REACT_APP_API+ "/helpdesk/categories/all";
let urlSubcategories = process.env.REACT_APP_API+ "/helpdesk/subcategories";

const PublicationsPage = (props) => {

    const [values, setValues] = useState({});
    const [open, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams([]);
    const [textSearch, setTextSearch ] = useState('');
    const [changed, setChanged] = useState(false);
    const { data : subcategories } = useFetchData(urlSubcategories);
    const { data : categories } = useFetchData(urlCategories);

    const [category, setCategory] = useState({});
    const [subcategory, setSubcategory] = useState({});
    const handleCategory = (cat) => {
        setCategory(categories.find(e => e.id === cat));
    }
    const handleSubCategory = (sub) => {
        setSubcategory(subcategories.find(e => e.id === sub));
    }    

    const handleChageParamCategory = () => {
        setSearchParams({  c: category?.id.toString() }, { replace: true })
        setChanged(!changed);    
    }

    const handleHome = () => {
        setChanged(!changed)
    }

    const breadcrumbs = [
        <Link className='breadcrumbs'  to={''} onClick={() => handleHome()} >
            Início {(category?.name) ? '❯'  : ''}
        </Link>,
        <h className='breadcrumbs' onClick={() => handleChageParamCategory()}    
        >
            {category?.name} {(category?.name) ? '❯'  : ''}
        </h>,
            <Typography key="3" color="text.primary" >
                {subcategory?.name}
            </Typography>,
      ];


    // paginação
    const filter = useMemo(() => {
        return searchParams.get('filter') || '';
    }, [searchParams]);    

    const page = useMemo(() => {
        return Number(searchParams.get('page') || '1');
    }, [searchParams]);

    const pub = useMemo(() => {
        return (searchParams.get('pub') || '');
    }, [searchParams]);

    const cat = useMemo(() => {
        return (searchParams.get('c') || 0);
    }, [searchParams]);


    const handleChangeParams = (event, value) => {
      setSearchParams({ filter, page: value.toString() }, { replace: true })
      setChanged(!changed);
    };
  

    const { data : publications } = useFetchData(url, {
        admin: props.admin, page: page-1, filter: filter, cat : cat    
    }, changed);

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleSearch = () => {    
        setSearchParams({ filter: textSearch, page: '1' }, { replace: false });
        setChanged(!changed);
    }

    const handleOpenPub = (pub, cat, sub) => {
        handleCategory(cat);
        handleSubCategory(sub);
        setSearchParams({ pub: pub.toString()},  { replace: false })
    }

    const HandleRenderPagePub = (theme) =>{
        return <PublicationViwenPage id={pub} />
    }

    const results = () => {
        if(publications?.length == 0){
            return 'Nenhuma publicações encontrada.. Verifique o filtro e tente novamente'
        } 
        return filter.length > 0 || cat.length > 0 ? publications[0].count + 
        ((publications[0].count == 1) ? ' publicação encontrada' : ' publicações encontradas' ) : 'Últimas Publicações'
    }

  return (
    <Box  container className='div-helpdesk' sx={{overflow: 'auto'}} > 
        { (!props.admin) ? <AppBarPrincipal/> : ''}    
        <SearchBar title='Em que podemos ajudar?' filter={textSearch} setFilter={setTextSearch} handleSearch={handleSearch}/>
        <Divider />
        <Stack spacing={1} alignItems="center" display={(!pub) ? 'none' : ''} sx={{m: 1}}>
            <Breadcrumbs
                separator={''}
                spacing={0}
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
        <Divider />
        <Box className="box-publications" display={(pub !== '') ? 'none' : ''}>
            <Box sx={{height: 'auto'}}>            
                <Grid container spacing={2} columns={1}  
                    sx={{width:{xs: "100%", sm: "90%", md: "80%", lg: "70%", xl: "50%"},  
                    paddingLeft: {xs: "5%", sm: "0%", md: "5%", lg: "10%", xl: "10%"}, mt: 2,}}>
                    <Typography variant='h4' gutterBottom>{results()}</Typography>
                    { publications && publications.map((pub) => (
                        <Grid key={pub.id} item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <CardPub 
                                values={pub} 
                                setComponent={props.setComponent}  
                                handleOpen={handleOpen} 
                                setValues={setValues} 
                                handleOpenPub={handleOpenPub} 
                                admin={props.admin}
                            />
                        </Grid>  
                    ))}
                </Grid>
            {(props.admin) ? 
            <CriarPub 
                values={values} 
                open={open} 
                handleClose={handleClose} 
                handleOpen={handleOpen} 
                setValues={setValues} 
                setChanged={setChanged} 
                changed={changed}
                categories={categories}
                subcategories={subcategories}
                /> : ''}
                             
            </Box>
            <div className='pagination-pubs'>
                <Stack spacing={2} display={(publications.length > 0) ? (publications[0].count > 10) ? '' : 'none' : 'none'}>
                    <Pagination 
                        count={(publications.length > 0) ? Math.ceil(publications[0].count/10) : 0} 
                        page={page} 
                        onChange={handleChangeParams} 
                        color="primary"
                    />
                </Stack>
            </div>
        </Box> 
        <Box className="box-view-pub" 
            display={(pub !== '') ? '' : 'none'}>
            {HandleRenderPagePub(props.theme)}
        </Box>                        
    </Box>
  )
}

export default PublicationsPage