import { Box, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import React, { useState } from 'react';
import "./Login.css";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuthUser } from '../../../hooks/useAuthUser/useAuthUser';
import ShowSnackbars from '../../../components/menssages/ShowSnackBar';


const LoginPageAdmin = () => {
    const { signIn } = useAuthUser();
    const [user, setUser] = useState("");

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [buttonEnabled, setButtonEnabled] = React.useState(true);
   
    // Validando Login
    const [password, setPassword] = useState("");

    const [msg, setMsg] = useState({
      open: false,
      text: '',
      type: 'success'
    });
  
    const showMsg = (type, msg) => {
      setMsg({
        open: true,
        text: msg,
        type: type
      })
    }       

    const handleLogin = async (e) => {  

      if (!user | !password ) {
        showMsg('error', 'Preencha todos os campos!')
        return;
      }
  
      try {
        setButtonEnabled(false);
        const res = await signIn(user, password);
 
        if (!res.Authenticated) {
          showMsg('error', res.msg)
          return;
        }
       
       // navigate("/admin"); 
        window.location.reload(false);

      }
      catch(e){
        return;
      }
      finally {
        setButtonEnabled(true);
      }

    };


  return (
    <div >
        <Box >
        <div className='div-login-fundo-admin' 
        style={{
          backgroundImage: `url(/img/FUNDO.png)`
        }}>
            <div className="div-panel-login">
                <div className='box-login-admin'>
                <h2  variant="h5">
                     Sirrus Atendimento 
                </h2>
                <h3 className='title-bem-vindo'>Seja bem vindo! </h3>

                    <Divider sx={{ m: 2}}/>
                    <TextField sx={{ m: 1, width: '30ch' }}
                            
                            value= {user}
                            id="outlined-required"
                            label="Usuário"
                            name="user"
                            onChange={(e) => setUser(e.target.value)}
                    />  
                    <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
                    <InputLabel  htmlFor="outlined-adornment-password">Senha</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        required
                        value= {password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        onKeyDown={(e) => {if(e.key === "Enter")handleLogin()}}
                        endAdornment={
                        <InputAdornment  position="end">
                            <IconButton 
                            aria-label="toggle password visibility"  
                            onClick={handleClickShowPassword}                     
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Senha"
                    />
                    </FormControl>  

                    <Button 
                        disabled={!buttonEnabled}
                        variant="outlined" 
                        sx={{ m: 1, width: '32ch', height: '7ch', marginTop: 4}}
                        onClick={() => handleLogin()}
                        type="submit"
                    >   
                        Entrar
                    </Button>    
                    <div className='div-by_admin' >
                      <p>&copy; Sirrus Sistemas {new Date().getFullYear()}</p>
                      <p> Desenvolvido por Ricardo Costa</p>
                    </div> 
                </div> 
            </div>
        </div>
        <ShowSnackbars msg={msg} setMsg={setMsg}/>
        </Box>
    </div>
  )
}

export default LoginPageAdmin