import React, { useEffect, useMemo, useState } from 'react'
import SearchBar from '../../../searchBar/SearchBar'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ShareIcon from '@mui/icons-material/Share';

import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import ViewImage from '../modalImage/ViewImage';
import { Pagination, SpeedDial, SpeedDialIcon, Stack, Tooltip } from '@mui/material';
import Image from '../image/Image';
import { Box } from '@mui/system';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import { useSearchParams } from 'react-router-dom'
import { useCryptoData } from '../../../../hooks/useCryptoData/useCryptoData'; 
let url = process.env.REACT_APP_API+ "/images";


const PageImages = () => {
    const { decryptPermissionsTree } = useCryptoData();
    const user = decryptPermissionsTree(sessionStorage.getItem('data'));
    const [searchParams, setSearchParams] = useSearchParams([]);
    const [textSearch, setTextSearch ] = useState('');
    const [image, setImage] = useState({});
    const [changed, setChanged] = useState(false);
    const [openViewImage, setOpenViewImage] = useState(false);
    const [open, setOpen] = useState(false);
    const [editing, setEditing] = useState(false);
    const { ShowMessage } = useShowMessages();

    // paginação

    const filter = useMemo(() => {
      return searchParams.get('filter') || '';
    }, [searchParams]);    

    const pageIndex = useMemo(() => {
      return Number(searchParams.get('pageIndex') || '1');
    }, [searchParams]);


    const handleChangePage = (event, value) => {
      setSearchParams({ filter, pageIndex: value.toString() }, { replace: false })
      setChanged(!changed);
    };

    const handlerSearch = () => {

      // (textSearch.length > 0) ? setTextHeaderPubs('Resultados encontrados') : setTextHeaderPubs('Últimas Publicações');

      setSearchParams({ filter: textSearch, pageIndex: '1' }, { replace: true })
      setChanged(!changed)
      
    }


    const { data : listImages } = useFetchData(url, {
      user: user?.id,
      pageIndex: pageIndex-1,
      filter: filter     
    }, changed);


    const handleNewImage = () => {
      setEditing(false);
      setImage({});
      setOpen(true);
    }


    const handleViewImage = (img) => {
      setImage(img);
      setOpenViewImage(true); 
    }
    const columns = () => {
        if (window.screen.width > 1280) return 7
        else if (window.screen.width > 1000) return 5
        else if (window.screen.width > 800) return 3
        else if (window.screen.width > 500) return 2
        else return 1
    }

    const handleEditImage = (img) => {
      setEditing(true);
      setImage(img);
      setOpen(true);
    }

    const copyToClipBoard = async (url) => {
      await navigator.clipboard.writeText(url);
      ShowMessage('success', 'Copiado para área de transferência');
    }

  return (
    <Box sx={{overflow: 'scroll', width: '100%', height: '100%'}} >
        <SearchBar
            titleSearch ='Busque por imagens'
            handleSearch={handlerSearch}
            textSearch={textSearch}
            setFilter={setTextSearch}
        />
         <ViewImage image={image} open={openViewImage} setOpen={setOpenViewImage}/>
         <Image image={image} editing={editing} open={open} setOpen={setOpen} setImage={setImage} setChanged={setChanged} changed={changed}/>
        <ImageList 
        sx={{ width: '100%', typography: 'body1', marginTop: 1, p: 2}} 
       
        cols={columns()} gap={8} rowHeight={170}>
                {listImages && listImages.map((item) => (
                 
            <ImageListItem key={item.id}  style={{border: '1px solid', overflow: 'hidden'}}>
              <img
                  src={item.path}
                  alt={item.title}
                  loading="lazy"
                  onClick={() => handleViewImage(item)} 
                  srcSet={item.path}
                  width="100%"
                  style={{height: '80%'}}
              />
            <ImageListItemBar sx={{ml: 1, md: 1, bottom: "1px"}}
              title={item.title}
              actionIcon={
                <Box sx={{bottom: 0, display: 'flex'}}>
                  <Tooltip title="Detalhes">
                    <IconButton onClick={() => handleEditImage(item)}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copiar link">
                    <IconButton onClick={() => copyToClipBoard(item.path)}>
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
              position="below"
            />
          </ImageListItem>
        ))}
        </ImageList>   
        <div className='pagination-pubs'> 
                <Stack spacing={2} display={(listImages.length > 0) ? (listImages[0].count > 21) ? '' : 'none' : 'none'}>
                    <Pagination 
                        count={(listImages.length > 0) ? Math.ceil(listImages[0].count/21) : 0} 
                        page={pageIndex} 
                        onChange={handleChangePage} 
                        color="primary"
                    />
                </Stack>
            </div>        
        <div>
        <SpeedDial
              ariaLabel="Nova imagem"
              sx={{ position: 'fixed', bottom: 16, right: 16, }}
              icon={<SpeedDialIcon  />}
              onClick={() => handleNewImage()}
              >
          </SpeedDial>
        </div>         
    </Box>
  )
}


export default PageImages