import React, { useState,  useMemo, useEffect } from 'react'
import axios from 'axios';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import DOMPurify from 'dompurify'
import { Block } from '@mui/icons-material';
let url = process.env.REACT_APP_API+'/helpdesk/publications/pub';

const BodyPub = (props) => {
  return <div dangerouslySetInnerHTML={{ __html: props.content }} />;
}

const PublicationViwenPage = () => {
    const [values, setValues] = useState({});
    const [searchParams] = useSearchParams([]);
    const navigate = useNavigate();
    const pub = useMemo(() => {
      return (searchParams.get('pub') || '');
    }, [searchParams]);

    useEffect( () => {
      axios.get(url, { params: { id: pub } })
     .then((res) => {
       if (res.data){
         setValues(res.data[0]);      
       } ;
     })
     .catch(function (error) {
         console.error(error.toJSON());
     });   
    }, [pub]);

  return (
    <Box sx={{width:{xs: "95%", sm: "90%", md: "80%", lg: "70%", xl: "50%"}, height: '100%'}}>
            <Typography variant="h4"  >
              {values?.title} 
            </Typography>
            <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 1, mb: 1}} >
              <Typography variant="h7" color={'primary'}  gutterBottom>
                Autor: {values?.author}
              </Typography> 
              <Typography fontSize={12} color="gray" >
                Publicado em: {values?.created_at}
              </Typography> 
            </Box> 
            <Box > 
              <BodyPub content={(values.content) ? values.content : '<p>Carregando..</p>'} sx/>
              <Typography variant="h7" 
                sx={{cursor: 'pointer',  bottom: 10}}
                gutterBottom 
                onClick={() => navigate(-1)}
                color={blue[700]}
                cursor={ 'pointer'}
              >
               &crarr;  Voltar 
              </Typography>
            </Box>
    </Box>
  )
}

export default PublicationViwenPage