import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, NavLink , useNavigate} from 'react-router-dom';
import './Appbar.css'
import RequestClient from '../requests/requestsModais/requestClient/requestClient';
import { Divider } from '@mui/material';
import { useAuthClient } from '../../hooks/useAuthClient/useAuthClient';

const pages = [
    {
        id: 1,
        link: '/home',
        title:'Início' 
    },
    {
        id: 1,
        link: '/atendimentos',
        title:'Atendimentos' 
    },
    {
        id: 1,
        link: '/manuais',
        title:'Manuais' 
    } ,
    {
      id: 4,
      link: '/h',
      title: 'HelpDesk'
    }        
];


const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const client = JSON.parse(sessionStorage.getItem('client'));

function AppBarPrincipal(props) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [open, setOpen] = useState(false);
  const { signOut } = useAuthClient();
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
    navigate("/");  
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" className='app-bar'>
      <Container maxWidth="1920" >
        <Toolbar disableGutters sx={{overflow: 'visible', height: '68.5px', display: 'flex', }}>
          <Box className='logoSirrus'>
            <img 
              src="/img/LOGO2.png"  alt="Logo Sirrus"  height={150}  
            />
          </Box>
          {/* Menus para telas pequenas */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{display: { xs: 'block', lg: 'none' },}}
            >
              {pages.map((page) => (
                <MenuItem 
                    key={page.key} 
                    onClick={handleCloseNavMenu}
                    component={NavLink}
                    to= {page.link}
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>  
          {/* Botões para telas grandes */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
            {pages.map((page) => (
              <NavLink
                to= {page.link}
                className='menus-navigation'
              >
                <b>{page.title}</b>
              </NavLink>
            ))}
          </Box>
          <RequestClient  open={open} setOpen={setOpen} admin={false} handleChanged={props.handleChanged} requestsPage={props.requestsPage} />
          <Box sx={{ flexGrow: 0 }}>   
            <Tooltip title='Sair' color='white'>
              <IconButton onClick={handleSignOut} sx={{  color: 'white' }}>
                <LogoutIcon/>
              </IconButton>
            </Tooltip>      
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AppBarPrincipal;