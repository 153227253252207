import { Box, SpeedDial, SpeedDialIcon, Typography, containerClasses } from '@mui/material';
import React, { useState } from 'react'
import Client from '../../../../components/administrative/clients/client/client';
import ClientsConnectedTable from '../../../../components/actualizations/clientsConnectedTable';

const ClientsActualizations = () => {
  const [open, setOpen] = useState();
  const [values, setValues] = useState({});
  const [editing, setEditing] = useState(false);
  const [changed, setChanged ] = useState(false); 

  const handleChanged = () => {
    setChanged(!changed);
  }

  const handleNewClient = () => {
    setValues({
          hour_technical: 'N',
          show_alert: 'N',
        });
    setEditing(false);
    setOpen(true);
  }

  return (
    <div style={{padding: 20, overflow: 'scroll', width: '100%', height: '100%'}}>
      <Box className="header-clients">
        <Typography variant="h4"  color="text.secondary" >
          Listagem de Clientes - Atualização
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Realize a atualização dos clientes listados 
        </Typography>
      </Box>
      <Box>
        <Client 
          open={open}
          setOpen={setOpen} 
          values={values}
          setValues={setValues}
          editing={editing}
          setEditing={setEditing}
          handleChanged={handleChanged}
        />
      </Box>
      <ClientsConnectedTable
        open={open}
        setOpen={setOpen} 
        values={values}
        setValues={setValues}
        editing={editing}
        setEditing={setEditing}
        changed={changed}
        handleChanged={handleChanged}
        actualizations={true}
      />
    </div>
  )
}

export default ClientsActualizations;