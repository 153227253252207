let axios = require('axios');
let url = process.env.REACT_APP_API+ "/client/login/";

export const useAuthClient = () => {

  const signIn = async (user) => {

    return await axios.get(url, {
      params: {
        user: user,
      }
    })
    .then(function (response) {
      sessionStorage.setItem('client', JSON.stringify(response.data.user));
      sessionStorage.setItem('clientToken', response.data.token);
      return { Authenticated : true, msg: response.data.message} 
    })
    .catch(function (error) {

      if (error.response) {
        console.log(error.response)
        return {
          Authenticated : false,
          msg: error.response.data.message
        } 
      }
    })
  };

  const signOut = () => {
    sessionStorage.clear();
  };

  return { signIn, signOut }

}
