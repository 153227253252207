import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  // toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 3000,
  // timerProgressBar: true,
  // didOpen: (toast) => {
  //   toast.addEventListener('mouseenter', Swal.stopTimer)
  //   toast.addEventListener('mouseleave', Swal.resumeTimer)
  // }
})


export const useShowMessages = () => {

    const ShowMessage = (type, title, msg, time = 3) => {
      if(type === 'warning'){
        return  Swal.fire({
                  icon: type,
                  title: title,
                  text: msg,
                  showConfirmButton: true,
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Confirmar',
                })
      } else {
        return  Toast.fire({
                  icon: type,
                  title: title,
                  text: msg,
                  timer: time*1000
                })
      }

    }

    const msgConfirm = (title, msg, btnConfirm, btnCancel, callback) => {
      Swal.fire({
        title: title,
        text: msg,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: btnConfirm,
        cancelButtonText: btnCancel
      }).then((result) => {
        if (result.isConfirmed) {
          callback();
        }
      })
      
    }

    return{ ShowMessage, msgConfirm }
}