import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, IconButton, InputBase } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

//Hooks
import { useFetchData } from '../../hooks/useFetchData/useFetchData';
import moment from 'moment-timezone';
import { useShowMessages } from '../../hooks/useMessages/useMessages';
import Loading from '../loading/loading';

let url = process.env.REACT_APP_API+ "/actualizations";

export default function SystemsTable(props) {
  const [changed, setChanged] = useState(false);
  const { ShowMessage, msgConfirm } = useShowMessages();
  const { data : listGroups, editData, loading } = useFetchData(url + '/getSystems', {}, changed, true, true);

  const handleChanged = () => {
    setChanged(!changed);
  }  

  const alterStatus = async (id, status) => {
    let values = {
      id: id,
      status: status
    }
    let response = await editData(url + '/updateStatusSystem', values);
    ShowMessage((response.success) ? 'success' : 'error', (response.success) ? 'Sucesso' : 'Ops..', response.msg);
    handleChanged();
  }

  const renameFile = async (file, newName) => {
    const blob = file.slice(0, file.size, file.type);
    return new File([blob], newName, { type: file.type });
  };


  const handleClickSend = async (file, system) => {

    if(!file){
      return 
    }

    msgConfirm('Atualização de Sistema', 'Confirma a atualização do arquivo?',
      'Confirmar', 'Cancelar', async () => {
        const newFile = await  renameFile(file, system.Arquivo);
        console.log(newFile)
        const formData = new FormData();
        formData.append('file', newFile);
        formData.append('id', system.id);
        let res = await editData(url + '/uploadSystem', formData);
    
        if(res.success){
          ShowMessage('success', res.msg);    
          handleChanged();
        }else{
          ShowMessage('error', res.msg);
        }
    })
  }


  const handleUploadClick = async (event, system) => {
    let file = event.target.files[0];
    handleClickSend(file, system)
  };

  return (
    <Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}> 
        <Loading open={loading} /> 
        <TableContainer sx={{ overflow: 'scroll', height: '100%vh'}}>
          <Table stickyHeader  sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                  <TableCell align="left">Sistema</TableCell>
                  <TableCell align="left">Tipo</TableCell> 
                  <TableCell align="center">Versão</TableCell> 
                  <TableCell align="center">Data</TableCell> 
                  <TableCell align="center">Status</TableCell>                                              
                  <TableCell align="center">Ações</TableCell>                                              
              </TableRow>
            </TableHead>
            <TableBody>
            {listGroups?.map && listGroups.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" align="left">
                  {row?.sistema}
                </TableCell>
                <TableCell align="left">{row?.tipo}</TableCell>
                <TableCell align="center">{row?.Versao}</TableCell>
                <TableCell align="center">{moment(row?.AtualizadoEm).format("DD/MM/YYYY HH:mm ")}</TableCell>
                <TableCell align="center">
                  {
                    (row.ativo) ? 
                    <Button onClick={() => alterStatus(row?.id, false)} variant="outlined" color="error">Desativar</Button>
                    :
                    <Button onClick={() => alterStatus(row?.id, true)} variant="outlined" color="success">Ativar</Button>
                  }
                </TableCell>
                <TableCell align="center">
                <Button variant="outlined" color="primary"  component="label" >
                  <FileUploadIcon htmlFor="contained-button-file"  /> Upload 
                  <input
                    type="file"
                    hidden
                    accept=".zip"             
                    id="contained-button-file"
                    onChange={(e) => handleUploadClick(e, row)}
                  />
                </Button>
                {/* <label htmlFor="contained-button-file" >
                  <Button  variant="outlined" color="primary" component="label">
                    <FileUploadIcon htmlFor="contained-button-file"  /> Upload 
                  </Button>
                </label> */}
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}